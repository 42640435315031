import { ListItem, UnorderedList } from '@chakra-ui/react'

import { EditorPanelItem } from 'interfaces/editor'

interface LayerSimpleProps {
  item: EditorPanelItem
}

const LayerSimple = ({ item: { label, children, isCountable, defaultCollapsed } }: LayerSimpleProps) => (
  <ListItem>
    {label} {isCountable && children ? `(${children.length})` : ''}
    {children?.length && !defaultCollapsed ? (
      <UnorderedList>
        {children.map((child) => (
          <LayerSimple key={child.key} item={child} />
        ))}
      </UnorderedList>
    ) : null}
  </ListItem>
)

export default LayerSimple
