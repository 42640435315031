import { Box, HStack, IconButton, Spacer, Spinner, Square, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { CollapseIcon, DeleteIcon, ExpandIcon, HideIcon, ShowIcon, UnsavedIcon } from 'assets/icons'

import { EditorPanelItem } from 'interfaces/editor'

import useElementsPanel from '../hooks/useElementsPanel'
import { getId, getIdsForDeletion } from '../utils'

interface LayerProps {
  item: EditorPanelItem
  depth: number
  isParentWorking?: boolean
  collapsible?: boolean
  toolsPanelItems: EditorPanelItem[]
}

export const Layer = ({
  item: current,
  depth,
  isParentWorking = false,
  collapsible = true,
  toolsPanelItems,
}: LayerProps) => {
  const {
    key,
    label,
    dropdown,
    children,
    item,
    isCountable = false,
    isWorking = false,
    isSaving = false,
    isVirtualContainer = false,
    onSelect = true,
    onHover,
    onDelete,
    toggleVisibility,
  } = current

  // Store
  const isElementDeleting = useSelector((state: RootState) => state.editor.isElementDeleting)
  const deletingElementIds = useSelector((state: RootState) => state.editor.deletingElementIds)

  // Hook
  const {
    collapsed,
    isInvisible,
    isSelected,
    handleToggleVisibility,
    handleDelete,
    handleSelect,
    handleHover,
    setCollapsed,
  } = useElementsPanel(current)

  return (
    <>
      <HStack
        width="100%"
        gap={0}
        px={2}
        borderTop="1px solid transparent"
        borderBottom="1px solid transparent"
        cursor={onSelect ? 'pointer' : 'default'}
        backgroundColor={isSelected ? '#3F444E' : undefined}
        _hover={
          onSelect
            ? {
                borderTop: '1px solid var(--chakra-colors-gray-700)',
                borderBottom: '1px solid var(--chakra-colors-gray-700)',
                backgroundColor: isSelected ? 'whiteAlpha.50' : 'gray.900',
              }
            : undefined
        }
        color={isWorking || isParentWorking ? 'yellow.400' : undefined}
        onClick={onSelect ? handleSelect : undefined}
        onMouseEnter={onSelect || onHover ? () => handleHover(true) : undefined}
        onMouseLeave={onSelect || onHover ? () => handleHover(false) : undefined}
        position="relative"
      >
        {isSelected ? (
          <Box width="5px" backgroundColor="#3F444E" height="100%" position="absolute" top={0} left={0} />
        ) : null}

        {/* indentation for children */}
        <Box width={`${depth * 12}px`} />

        {/* Chevron for collapsing children */}
        {collapsible && children?.length ? (
          <IconButton
            aria-label={collapsed ? 'expand' : 'collapse'}
            icon={collapsed ? <ExpandIcon /> : <CollapseIcon />}
            onClick={(e) => {
              e.stopPropagation()
              setCollapsed(!collapsed)
            }}
            variant="panel-icon"
          />
        ) : null}
        {collapsible && !children?.length ? <Square size={7} /> : null}

        {/* Visibility toggle */}
        {item || toggleVisibility || toggleVisibility === null || (isVirtualContainer && children?.length) ? (
          <IconButton
            icon={isInvisible ? <HideIcon /> : <ShowIcon />}
            aria-label={isInvisible ? 'hidden' : 'shown'}
            variant="panel-icon"
            isDisabled={toggleVisibility === null}
            onClick={toggleVisibility !== null ? handleToggleVisibility : undefined}
          />
        ) : (
          <Square size={0} />
        )}

        {/* Label & Icons */}
        <HStack gap={0.5}>
          <Text key={key} mx={1}>
            {label} {isCountable && children ? `(${children.length})` : ''}
          </Text>
          {isWorking && !isSaving && !dropdown ? <UnsavedIcon size="1.2em" /> : ''}
          {isSaving ? <Spinner size="xs" /> : ''}
        </HStack>

        {
          // ## Dropdown options
          dropdown
        }

        {!dropdown ? <Spacer /> : null}

        {
          // ## Delete button
          !!((item && onDelete !== false) || onDelete) && !isElementDeleting && !dropdown ? (
            <IconButton
              aria-label="delete"
              icon={<DeleteIcon />}
              variant="panel-icon"
              onClick={(e) => {
                e.stopPropagation()
                return handleDelete(getIdsForDeletion(current), toolsPanelItems)
              }}
            />
          ) : null
        }

        {
          // Spinner during delete
          isElementDeleting && deletingElementIds.includes(getId(current)) ? <Spinner size="xs" /> : null
        }
      </HStack>
      {!collapsed &&
        children?.map((child) => (
          <Layer
            key={child.key}
            isParentWorking={isWorking || isParentWorking}
            item={child}
            depth={depth + 1}
            toolsPanelItems={toolsPanelItems}
          />
        ))}
    </>
  )
}
