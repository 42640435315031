import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { isMobile } from 'react-device-detect'

import { LayerStatus, PointArray } from 'interfaces/attribute'
import { VolumeEstimationMethods } from 'interfaces/inspection'
import { PlaneSide } from 'interfaces/shape'

export const PLANE_CREATION_ORDER_DEFAULT: PlaneSide[] = [PlaneSide.UPPER, PlaneSide.LOWER]
export const WORKING_PLANE_LOWER_ID = 'working-volume-polygon-lower-plane'
export const WORKING_PLANE_UPPER_ID = 'working-volume-polygon-upper-plane'
export const PANEL_WORKING_PLANE_UPPER_ID = `panel-${WORKING_PLANE_UPPER_ID}`
export const PANEL_WORKING_PLANE_LOWER_ID = `panel-${WORKING_PLANE_LOWER_ID}`

export enum DrawingStage {
  Draw,
  Height,
  Complete,
}

interface PlaneData extends LayerStatus {
  points: PointArray[]
  anchorAdjustedPoints?: PointArray[]
  originalPoints?: PointArray[]
  isDoubleClickClosed?: boolean
}

export interface VolumeEstimationPolygonState {
  /**
   * Drawing stage.
   */
  drawingStage: DrawingStage

  /**
   * Working points when drawing polygon.
   */
  workingPoints: PointArray[]

  /**
   * Completed planes.
   */
  planes: Record<PlaneSide, PlaneData | null>

  /**
   * Point user selects/hovers to set height.
   */
  heightSelectionPoint: PointArray | null

  /**
   * New volume is being saved.
   */
  isSaving: boolean

  /**
   * Existing volume is being updated.
   */
  isUpdating: boolean

  /**
   * Flag to indicate if the polygon is closing.
   */
  isClosing: boolean

  /**
   * Flag to indicate if the tool is dirty (has working items).
   */
  isDirty: boolean

  /**
   * Plane creation order.
   */
  planeCreationOrder: PlaneSide[]

  /**
   * Transform quaternion from TransformControl applied from planes.x.anchorAdjustedPoints onto planes.x.points.
   */
  currentTransformQuaternion: number[] | null

  /**
   * Selected volume estimation method.
   */
  estimationMethod: VolumeEstimationMethods

  /**
   * Flag to indicate if the rebar modelled is confirmed.
   */
  rebarModelledConfirmed: boolean
}

const updatePointsAtIndex = (points: PointArray[], index: number, position: PointArray) => {
  points[index] = position

  // If first point is updated, update the last point as well, and vice versa.
  if (index === 0) {
    points[points.length - 1] = position
  }

  if (index === points.length - 1) {
    points[0] = position
  }

  return points
}

const initialState: VolumeEstimationPolygonState = {
  drawingStage: DrawingStage.Draw,
  isSaving: false,
  isUpdating: false,
  isClosing: false,
  isDirty: false,
  workingPoints: [],
  heightSelectionPoint: null,
  planeCreationOrder: PLANE_CREATION_ORDER_DEFAULT,
  planes: {
    upper: null,
    lower: null,
  },
  currentTransformQuaternion: null,
  estimationMethod: VolumeEstimationMethods.Standard,
  rebarModelledConfirmed: false,
}

export const slice = createSlice({
  name: 'editor/tools/volumeEstimation/polygon',
  initialState,
  reducers: {
    setIsSaving: (state, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload
    },
    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload
    },
    setClosing: (state, action: PayloadAction<boolean>) => {
      state.isClosing = action.payload
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload
    },
    setPlaneCreationOrder: (state, action: PayloadAction<PlaneSide[]>) => {
      state.planeCreationOrder = action.payload
    },
    setHeightSelectionPoints: (state, action: PayloadAction<PointArray | null>) => {
      state.heightSelectionPoint = action.payload
    },
    addWorkingPoint: (state, action: PayloadAction<PointArray>) => {
      state.workingPoints.push(action.payload)
      state.isDirty = true
    },
    setWorkingPoints: (state, action: PayloadAction<PointArray[]>) => {
      state.workingPoints = action.payload
    },
    resetWorkingPoints: (state) => {
      state.workingPoints = []
    },
    updateLastWorkingPoint: (state, action: PayloadAction<PointArray>) => {
      if (state.workingPoints.length) {
        state.workingPoints[state.workingPoints.length - 1] = action.payload
      } else {
        state.workingPoints.push(action.payload)
      }
    },
    updateBottomPlaneAnchor: (state, action: PayloadAction<{ index: number; position: PointArray }>) => {
      if (state.planes.lower) {
        state.planes.lower.points[action.payload.index] = action.payload.position

        // If first point is updated, update the last point as well, and vice versa.
        if (action.payload.index === 0) {
          state.planes.lower.points[state.planes.lower.points.length - 1] = action.payload.position
        }

        if (action.payload.index === state.planes.lower.points.length - 1) {
          state.planes.lower.points[0] = action.payload.position
        }
      }
    },
    completeBottomPlane: (
      state,
      { payload: { removeLast, isDoubleClicked } }: PayloadAction<{ removeLast: boolean; isDoubleClicked: boolean }>,
    ) => {
      if (state.planes.upper) {
        state.planes.lower = {
          points: [...state.workingPoints],
        }
        state.workingPoints = []
      } else {
        state.planes.lower = {
          points: [...(removeLast ? state.workingPoints.slice(0, -1) : state.workingPoints), state.workingPoints[0]],
          isDoubleClickClosed: isDoubleClicked,
        }
      }
    },
    updateTopPlaneAnchor: (state, action: PayloadAction<{ index: number; position: PointArray }>) => {
      if (state.planes.upper) {
        updatePointsAtIndex(state.planes.upper.points, action.payload.index, action.payload.position)
      }
    },
    updateTopPlaneAnchorAdjustedPoints: (state, action: PayloadAction<{ index: number; position: PointArray }>) => {
      if (state.planes.upper?.anchorAdjustedPoints) {
        updatePointsAtIndex(state.planes.upper.anchorAdjustedPoints, action.payload.index, action.payload.position)
      }
    },
    setCurrentTransformQuaternion: (state, action: PayloadAction<number[] | null>) => {
      state.currentTransformQuaternion = action.payload
    },
    completeTopPlane: (
      state,
      { payload: { removeLast, isDoubleClicked } }: PayloadAction<{ removeLast: boolean; isDoubleClicked: boolean }>,
    ) => {
      if (state.planes.lower) {
        state.planes.upper = {
          points: [...state.workingPoints],
          anchorAdjustedPoints: [...state.workingPoints],
          originalPoints: [...state.workingPoints],
        }
        state.workingPoints = []
      } else {
        state.planes.upper = {
          points: [...(removeLast ? state.workingPoints.slice(0, -1) : state.workingPoints), state.workingPoints[0]],
          anchorAdjustedPoints: [
            ...(removeLast ? state.workingPoints.slice(0, -1) : state.workingPoints),
            state.workingPoints[0],
          ],
          originalPoints: [
            ...(removeLast ? state.workingPoints.slice(0, -1) : state.workingPoints),
            state.workingPoints[0],
          ],
          isDoubleClickClosed: isDoubleClicked,
        }
      }
    },
    /**
     * Update top plane without updating original points.
     */
    updateTopPlane: (state, action: PayloadAction<{ points: PointArray[] }>) => {
      state.planes.upper = {
        ...state.planes.upper,
        points: action.payload.points,
      }
    },
    swapPlanes: (state) => {
      const temp = state.planes.upper
      state.planes.upper = state.planes.lower
      state.planes.lower = temp
    },
    setDrawingStage: (state, action: PayloadAction<DrawingStage>) => {
      state.drawingStage = action.payload
    },
    resetTopPlane: (state) => {
      state.planes.upper = null
      if (state.planeCreationOrder[0] === PlaneSide.UPPER) {
        state.drawingStage = DrawingStage.Draw
        state.workingPoints = []
      } else {
        state.drawingStage = DrawingStage.Height
      }

      state.currentTransformQuaternion = null

      if (!state.planes.lower?.points.length) state.isDirty = false
    },
    /**
     * Restore top plane to its original state after drawing completion.
     */
    restoreTopPlane: (state) => {
      state.planes.upper = {
        points: state.planes.upper?.originalPoints || [],
        anchorAdjustedPoints: state.planes.upper?.originalPoints || [],
        originalPoints: state.planes.upper?.originalPoints || [],
      }
      state.currentTransformQuaternion = null
    },
    resetBottomPlane: (state) => {
      state.planes.lower = null
      if (state.planeCreationOrder[0] === PlaneSide.LOWER) {
        state.drawingStage = DrawingStage.Draw
        state.workingPoints = []
      } else {
        state.drawingStage = DrawingStage.Height
      }

      if (!state.planes.upper?.points.length) state.isDirty = false
    },

    /**
     * Undo last working point, removing any planes if necessary.
     */
    undoLastWorkingPoint: (state) => {
      // If we have planes, eithe re-open or remove the second plane.
      // If we're in height selection, re-open the first plane.
      if (state.drawingStage === DrawingStage.Height) {
        if (state.planeCreationOrder[0] === PlaneSide.UPPER && state.planes.upper) {
          const { isDoubleClickClosed } = state.planes.upper
          state.workingPoints = state.planes.upper.points
          state.planes.upper = null
          state.drawingStage = DrawingStage.Draw

          if (isDoubleClickClosed) {
            state.workingPoints = state.workingPoints.slice(0, isMobile ? -2 : -1)
          } else if (isMobile) {
            state.workingPoints = state.workingPoints.slice(0, -1)
          }
        } else if (state.planeCreationOrder[0] === PlaneSide.LOWER && state.planes.lower) {
          state.workingPoints = state.planes.lower.points
          state.planes.lower = null
          state.drawingStage = DrawingStage.Draw
        }
      }
      // If we're in the complete stage, remove the last plane.
      else if (state.drawingStage === DrawingStage.Complete) {
        if (state.planeCreationOrder[0] === PlaneSide.UPPER && state.planes.lower) {
          state.planes.lower = null
          state.drawingStage = DrawingStage.Height
        } else if (state.planeCreationOrder[0] === PlaneSide.LOWER && state.planes.upper) {
          state.planes.upper = null
          state.drawingStage = DrawingStage.Height
        }
      }
      // If we're in the drawing stage, remove the last point.
      else if (state.drawingStage === DrawingStage.Draw && state.workingPoints.length) {
        if (isMobile) {
          state.workingPoints = state.workingPoints.slice(0, -1)
        } else {
          state.workingPoints = state.workingPoints.slice(0, -2).concat(state.workingPoints.slice(-1))
        }

        // If we have no more points, we're not dirty anymore.
        if (!state.workingPoints.length) state.isDirty = false
      }
    },

    /**
     * Estimation method panel actions.
     */
    setEstimationMethod: (state, action: PayloadAction<VolumeEstimationMethods>) => {
      state.estimationMethod = action.payload
    },
    setRebarModelledConfirmed: (state, action: PayloadAction<boolean>) => {
      state.rebarModelledConfirmed = action.payload
    },

    /**
     * Total reset of this tool
     */
    reset: () => initialState,
  },
})

export const {
  // Utility
  setIsSaving,
  setIsUpdating,
  setClosing,
  setIsDirty,
  setDrawingStage,
  setHeightSelectionPoints,
  setPlaneCreationOrder,
  setCurrentTransformQuaternion,

  // Working points
  addWorkingPoint,
  updateLastWorkingPoint,
  setWorkingPoints,
  resetWorkingPoints,

  // Completed planes
  updateBottomPlaneAnchor,
  completeBottomPlane,

  updateTopPlaneAnchorAdjustedPoints,
  updateTopPlaneAnchor,
  completeTopPlane,
  updateTopPlane,
  restoreTopPlane,

  swapPlanes,

  // Resets
  reset,
  resetTopPlane,
  resetBottomPlane,

  undoLastWorkingPoint,

  // Estimation method panel
  setEstimationMethod,
  setRebarModelledConfirmed,
} = slice.actions

export default slice.reducer
