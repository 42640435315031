import './styles.css'

import { HStack, IconButton, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ChevronRightIcon } from 'assets/icons'

import { PanelType } from 'config/constants'
import { INFO_PANEL_WIDTH } from 'config/styles'

import { EditorConfig } from 'interfaces/editor'

import { setCollapsedPanel } from '../store/editor'
import { ElementsPanel } from './ElementsPanel'
import SettingsPanel from './SettingsPanel'
import ToolsPanel from './ToolsPanel'

interface SidePanelProps {
  toolsConfig: (
    | {
        key: string
        auth: boolean
        hook: EditorConfig
      }
    | undefined
  )[]
}

const SidePanels = ({ toolsConfig }: SidePanelProps) => {
  // Store
  const dispatch = useAppDispatch()
  const collapsedPanel = useSelector((state: RootState) => state.editor.collapsedPanel)

  return (
    <HStack h="100%" id="panel-container">
      <IconButton
        bg="gray.900"
        color="white"
        borderRadius="md"
        icon={<ChevronRightIcon w="1.5rem" h="1.5rem" />}
        aria-label=""
        size="sm"
        className={['collapse-all-panel', collapsedPanel.length !== 3 ? '' : 'collapsed'].join(' ')}
        _hover={{ bg: 'gray.700' }}
        onClick={() =>
          dispatch(
            setCollapsedPanel(
              collapsedPanel.length !== 3 ? [PanelType.Settings, PanelType.Elements, PanelType.Tool] : [],
            ),
          )
        }
      />

      <VStack
        // TODO: adjust for responsive
        width={INFO_PANEL_WIDTH}
        h="100%"
      >
        <SettingsPanel />

        <ElementsPanel />

        <ToolsPanel toolsConfig={toolsConfig} />
      </VStack>
    </HStack>
  )
}

export default SidePanels
