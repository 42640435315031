import { useEffect, useMemo, useState } from 'react'

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { useEditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, PanelType } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import CollapsePanel from '../components/CollapsePanel'
import MaskingRegions from './MaskingRegions'
import Settings from './Settings'

const SettingsPanel = () => {
  // Context
  const { selectedTool } = useEditorContext()

  // Store
  const maskRegions = useSelector((state: RootState) => state.maskPCD.regions)

  // State
  const [tabIndex, setTabIndex] = useState(0)

  // Vars
  const isMaskRegionTool = useMemo(() => selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID, [selectedTool])

  useEffect(() => {
    if (isMaskRegionTool) {
      setTabIndex(1)
    } else if (!maskRegions.length) {
      setTabIndex(0)
    }
  }, [isMaskRegionTool, maskRegions.length])

  return (
    <Box data-testid="settings-panel" className="panel">
      <CollapsePanel title="設定" type={PanelType.Settings} onChange={() => null}>
        <Tabs
          index={tabIndex}
          onChange={(index) => setTabIndex(index)}
          w="100%"
          pb={INFO_PANEL_PADDING - 1}
          size="sm"
          variant="panel"
        >
          <TabList>
            <Tab>表示設定</Tab>
            {maskRegions.length || isMaskRegionTool ? <Tab>注目領域</Tab> : null}
          </TabList>

          <TabPanels>
            <TabPanel>
              <Settings />
            </TabPanel>
            <TabPanel px={0}>
              <MaskingRegions />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CollapsePanel>
    </Box>
  )
}

export default SettingsPanel
