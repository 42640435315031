/* istanbul ignore file */
import { ToolbarPolylineIcon } from 'assets/icons'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, USER_TYPES } from 'config/constants'

import { EditorTool, ToolbarCategory } from 'interfaces/editor'
import { PermissionSets } from 'interfaces/validation'

import useEditor from './hooks/useEditor'
import useElementsPanel from './hooks/useElementsPanel'
import useMainCanvas from './hooks/useMainCanvas'

const Polyline: EditorTool = {
  key: EDITOR_TOOLS.POLYLINE,
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) =>
    permissionSets.MAIN_CANVAS.DETECT.includes(userType),
  toolbar: {
    icon: <ToolbarPolylineIcon width="50%" height="50%" />,
    label: EDITOR_TOOLS_LABELS.POLYLINE,
    category: ToolbarCategory.Generate,
  },
  hooks: {
    useEditor,
    useMainCanvas,
    useElementsPanel,
  },
}

export default Polyline
