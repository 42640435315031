import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { useEditorContext } from 'contexts/Editor'

import { EditorPanelItem, ElementsPanelConfig } from 'interfaces/editor'

import { setHoveredGridPoint } from '../store'

const useElementsPanel = (): ElementsPanelConfig => {
  // Context
  const { inspectionItems } = useEditorContext()

  // Store
  const dispatch = useAppDispatch()
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  return {
    getPanelItems: useCallback(
      (parent?: EditorPanelItem['item']): EditorPanelItem[] => {
        // Grid falls under a volume
        if (!parent || !('item_type' in parent) || parent.item_type !== 'volume') return []

        const grid = inspectionItems.find(
          (item) => item.item_type === 'grid' && item.volume_id === parent.inspection_item_id,
        )
        if (grid) {
          return [
            {
              key: `panel-grid-${grid.inspection_item_id!}`,
              label: 'グリッド点',
              item: grid,
              defaultCollapsed: true,
              isCountable: true,
              onDelete: isAllowedToModify,
              children: grid.grid?.list_distances?.map((distance, index) => ({
                key: `panel-grid-point-${grid.inspection_item_id}-${index}`,
                label: distance.name,
                toggleVisibility: true,
                onSelect: false,
                onHover(flag) {
                  dispatch(
                    setHoveredGridPoint(flag ? { gridId: grid.inspection_item_id!, pointIndex: index } : undefined),
                  )
                },
              })),
            },
          ]
        }

        return []
      },
      [inspectionItems, isAllowedToModify, dispatch],
    ),
  }
}

export default useElementsPanel
