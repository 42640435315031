/* istanbul ignore file */
import { useCallback, useContext, useEffect, useMemo } from 'react'

import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { CursorState, setCursor } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_TOOLS, MAX_EDITOR_LAYERS } from 'config/constants'

import { Editor } from 'interfaces/canvas'
import { EditorConfig } from 'interfaces/editor'
import { InspectionItem } from 'interfaces/inspection'

import { addInspectionItems } from 'services/InspectionSheet'
import { pointArrayToVector3 } from 'services/Points'
import { zeroPad } from 'services/Util'

import {
  Polyline,
  deleteNonCompletedWorkingPolylines,
  resetWorkingPolylines,
  setFirstPointPlaced,
  setIsLoading,
  setPolylines,
  setWorkingLabel,
} from '../store'

const zeroPlaces = MAX_EDITOR_LAYERS.toString().length

export default function useEditor(props: Editor): EditorConfig {
  // Context
  const { inspectionItems, inspectionSheet, selectedTool, isPreviousTool, setInspectionItems, changeIsJobRunning } =
    props
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const isLoading = useSelector((root: RootState) => root.toolPolyline.isLoading)
  const isDirty = useSelector((root: RootState) => root.toolPolyline.isDirty)
  const workingPolylines = useSelector((state: RootState) => state.toolPolyline.workingPolylines)
  const project = useSelector((root: RootState) => root.page.project)
  const inspectionArea = useSelector((root: RootState) => root.page.inspectionArea)

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.POLYLINE, [selectedTool])
  const isPending = !workingPolylines.length || !workingPolylines.slice(-1)[0]?.completed

  /**
   * Save polyline to DB
   *
   * @param polyline Measurement to save
   */
  const savePolyline = useCallback(async () => {
    if (!project || !inspectionArea || !inspectionSheet || !workingPolylines.length) return

    const validPolylines = workingPolylines.filter(
      (polyline) => polyline.polyline_length?.positions_for_distance?.length,
    )

    const token = await getAccessToken()
    if (!token) return

    const results = await addInspectionItems(
      token,
      project.project_id,
      inspectionArea.inspection_area_id,
      inspectionSheet.inspection_sheet_id,
      validPolylines.map<Partial<InspectionItem>>((polyline) => {
        // Calculate total length of all sections of the polyline
        const positions = polyline.polyline_length?.positions_for_distance || []
        const totalLength = positions.reduce((acc, pos, index) => {
          if (!positions[index + 1]) return acc

          return acc + pointArrayToVector3(pos).distanceTo(pointArrayToVector3(positions[index + 1]))
        }, 0)

        return {
          part_name: '', // leave empty for generic name on display until user changes it
          item_type: 'polyline_length',
          polyline_length: {
            estimated_value: totalLength,
            positions_for_distance: polyline.polyline_length!.positions_for_distance,
          },
        }
      }),
      showErrorModal,
    )

    // update inspection items
    if (results) {
      dispatch(resetWorkingPolylines())
      setInspectionItems((items) => items.concat(results))
    }
  }, [
    workingPolylines,
    inspectionArea,
    inspectionSheet,
    project,
    dispatch,
    getAccessToken,
    setInspectionItems,
    showErrorModal,
  ])

  /**
   * Fetch polyline items from inspection items
   */
  useEffect(() => {
    const data = inspectionItems
      .filter((item) => item.item_type === 'polyline_length')
      .map<Polyline>((item, index) => ({
        ...item,
        part_name: item.part_name || `延長 ${zeroPad(index + 1, zeroPlaces)}`,
      }))

    dispatch(setPolylines(data))
  }, [inspectionItems, dispatch])

  /**
   * Toggle info panels when tool is selected
   */
  useEffect(() => {
    if (isToolSelected) {
      dispatch(
        setAttentionText({
          message:
            '点群上の任意の点をクリックして延長を計測します。最後の点をクリックするか、ダブルクリックすると描画を完了します。\n最後に右下の保存ボタンをクリックしてください。',
        }),
      )
    }
  }, [isToolSelected, dispatch])

  /**
   * When user changes tools (in or out of Polyline tool), update cursor.
   */
  useEffect(() => {
    if (isToolSelected) {
      dispatch(setCursor(CursorState.CROSSHAIR))
    } else if (isPreviousTool(EDITOR_TOOLS.POLYLINE) && selectedTool !== EDITOR_TOOLS.FOCUS) {
      dispatch(setCursor(CursorState.DEFAULT))
      dispatch(setWorkingLabel(undefined))
      dispatch(setFirstPointPlaced(false))
      dispatch(deleteNonCompletedWorkingPolylines())
    }
  }, [isToolSelected, selectedTool, isPreviousTool, dispatch])

  return {
    buttons: {
      submit: {
        key: 'save-polyline',
        label: '保存',
        loadingLabel: '保存中',
        onClick: async () => {
          if (!project || !inspectionArea?.down_sampled_file?.path || !inspectionSheet?.inspection_sheet_id) return

          const token = await getAccessToken()
          if (!token) return

          dispatch(setIsLoading(true))
          changeIsJobRunning(true)
          dispatch(setAttentionText({ message: '延長を保存中...' }))

          await savePolyline()

          dispatch(setIsLoading(false))
          changeIsJobRunning(false)
          dispatch(setAttentionText({ message: '' }))
        },
        isLoading: () => isLoading,
        isShown: () => isToolSelected || isLoading,
        isDisabled: () => !isDirty || isLoading || isPending,
      },
      reset: {
        onClick: useCallback(() => dispatch(resetWorkingPolylines()), [dispatch]),
        isShown: useCallback(() => isToolSelected || isLoading, [isToolSelected, isLoading]),
        isDisabled: useCallback(() => isLoading || !isDirty, [isLoading, isDirty]),
      },
      undo: {
        isShown: useCallback(() => isToolSelected || isLoading, [isToolSelected, isLoading]),
        isDisabled: useCallback(() => isLoading || !isDirty, [isLoading, isDirty]),
      },
    },
  }
}
