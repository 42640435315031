import { Line3, Matrix4, Mesh, Object3D, Vector3 } from 'three'
import { ArcballControls, TransformControlsGizmo, TransformControls as TransformControlsImpl } from 'three-stdlib'

import { EDITOR_DECIMAL_BASE } from 'config/constants'

import { AnchorPoints, CameraProfileState, PointArray } from 'interfaces/interfaces'

import { roundNumber } from './Util'

/**
 * Get the distance and the center point between two points
 * @param {AnchorPoints} anchor
 * @returns center point and distance
 */
export const calculateCenterAndDistance = (anchor: AnchorPoints): [PointArray, number] | undefined => {
  if (anchor.points.length !== 2) {
    return undefined
  }
  const line = new Line3(new Vector3(...anchor.points[0]), new Vector3(...anchor.points[1]))
  const center = line.getCenter(new Vector3())
  return [center.toArray(), roundNumber(line.distance(), EDITOR_DECIMAL_BASE)]
}

/**
 * Get the center position of a mesh. This will return localized
 * to the provided mesh, not world position.
 *
 * @param mesh ThreeJS mesh
 * @returns Vector3 position
 */
export const getCenterPoint = (mesh: Mesh): Vector3 => {
  const { geometry } = mesh
  const center = new Vector3()
  geometry.computeBoundingBox()

  if (!geometry.boundingBox) {
    return center
  }

  geometry.boundingBox.getCenter(center)
  return center
}

/**
 * Calculate the center between two points.
 *
 * @param pointA
 * @param pointB
 */
export const getCenterOfTwoPoints = (pointA: Vector3, pointB: Vector3): Vector3 => {
  const center = new Vector3()
  center.addVectors(pointA, pointB).multiplyScalar(0.5)
  return center
}

/**
 * Within Transform control, except for gizmos (which actually meshes) with X, Y or Z name,
 * hide and disable other gizmos/meshes that used for translation, rotation and scaling
 * @param mesh ThreeJS Mesh or Object3D
 */
/* istanbul ignore next */
const removeGizmo = (mesh: Mesh | Object3D) => {
  if (['XYZX', 'XYZY', 'XYZZ', 'XYZ', 'XY', 'YZ', 'XZ', 'E'].includes(mesh.name)) {
    mesh.visible = false
    mesh.layers.disableAll()
  }
}

/**
 * Remove unused gizmos from TransformControls
 * @param transformControl TransformControls
 */
/* istanbul ignore next */
export const removeUnsedGizmos = (transformControl: TransformControlsImpl) => {
  /* eslint-disable dot-notation */
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  const gizmo = transformControl['gizmo'] as TransformControlsGizmo

  gizmo['gizmo'].scale.children.forEach((mesh: Mesh) => {
    removeGizmo(mesh)
  })
  gizmo['gizmo'].translate.children.forEach((mesh: Mesh) => {
    removeGizmo(mesh)
  })
  gizmo['gizmo'].rotate.children.forEach((mesh: Mesh) => {
    removeGizmo(mesh)
  })
  gizmo['picker'].scale.children.forEach((mesh: Object3D) => {
    removeGizmo(mesh)
  })
  gizmo['picker'].translate.children.forEach((mesh: Object3D) => {
    removeGizmo(mesh)
  })
  gizmo['picker'].rotate.children.forEach((mesh: Object3D) => {
    removeGizmo(mesh)
  })
  /* eslint-enable @typescript-eslint/no-unsafe-call */
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
  /* eslint-enable dot-notation */
}

/**
 * Get the save state for ArcballControls.
 *
 * @param arcballControls Used as any due to accessing private properties.
 */
/* istanbul ignore next */
/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, no-underscore-dangle */
export const getArcballControlsCameraState = (arcballControls: ArcballControls): CameraProfileState => {
  const t = arcballControls as any
  return {
    arcballState: {
      cameraFar: t.camera.far,
      cameraFov: t.camera.fov,
      cameraMatrix: {
        elements: (t.camera.matrix as Matrix4).toArray(),
      },
      cameraNear: t.camera.near,
      cameraUp: { x: t.camera.up.x, y: t.camera.up.y, z: t.camera.up.z },
      cameraZoom: t.camera.zoom,
      gizmoMatrix: {
        elements: (t._gizmos.matrix as Matrix4).toArray(),
      },
    },
  }
}
/* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, no-underscore-dangle */
