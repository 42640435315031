import { FC, useCallback } from 'react'

import { Box, Button, HStack, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { PlaneSide } from 'interfaces/shape'

import { DrawingStage, setPlaneCreationOrder } from '../store'

const PlaneSideSelection: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const drawingStage = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.drawingStage)
  const planeCreationOrder = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.planeCreationOrder)

  // Flags
  const currentPlaneSide = planeCreationOrder[drawingStage === DrawingStage.Draw ? 0 : 1]
  const isDoneDrawing = drawingStage === DrawingStage.Complete

  /**
   * Depending on the drawing stage, the target plane to be working on will change.
   * - DrawingStage.Draw -> first element of the planeCreationOrder array.
   * - DrawingStage.HeightSelection -> second element of the planeCreationOrder array.
   */
  const getNewOrder = useCallback(
    (target: PlaneSide): PlaneSide[] => {
      // Get the opposite of target
      const opposite = target === PlaneSide.UPPER ? PlaneSide.LOWER : PlaneSide.UPPER

      if (drawingStage === DrawingStage.Draw) {
        return [target, opposite]
      }

      return [opposite, target]
    },
    [drawingStage],
  )

  return (
    <Box width="100%">
      <Text mb={2} fontSize="sm" color="white">
        作成する平面
      </Text>
      <HStack>
        <Button
          key="plane-side-selection-button-upper"
          data-testid="plane-side-selection-button-upper"
          variant={currentPlaneSide === PlaneSide.UPPER && !isDoneDrawing ? 'toolbar' : 'editorGhost'}
          size="sm"
          fontSize="xs"
          onClick={
            currentPlaneSide !== PlaneSide.UPPER
              ? () => dispatch(setPlaneCreationOrder(getNewOrder(PlaneSide.UPPER)))
              : undefined
          }
          isDisabled={isDoneDrawing}
          spinnerPlacement="end"
          justifyContent="center"
          flex="1"
        >
          仕上がり面
        </Button>
        <Button
          key="plane-side-selection-button-lower"
          data-testid="plane-side-selection-button-lower"
          variant={currentPlaneSide === PlaneSide.LOWER && !isDoneDrawing ? 'toolbar' : 'editorGhost'}
          size="sm"
          fontSize="xs"
          onClick={
            currentPlaneSide !== PlaneSide.LOWER
              ? () => dispatch(setPlaneCreationOrder(getNewOrder(PlaneSide.LOWER)))
              : undefined
          }
          isDisabled={isDoneDrawing}
          spinnerPlacement="end"
          justifyContent="center"
          flex="1"
        >
          斫り面
        </Button>
      </HStack>
    </Box>
  )
}

export default PlaneSideSelection
