import { FC, useCallback, useEffect } from 'react'

import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import { toggleSelectedElementIds } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ChevronDownIcon } from 'assets/icons'

import { useEditorContext } from 'contexts/Editor'

import { DIAMETERS, EDITOR_TOOLS, PanelType } from 'config/constants'
import { INPUT_GROUP_STYLES } from 'config/styles'

import { findDiameterKeyByValue } from 'services/Util'

import { setDiameter } from '../store'

const DiameterPanel: FC = () => {
  // Context
  const { selectedTool } = useEditorContext()

  // Store
  const dispatch = useAppDispatch()
  const baseDiameter = useSelector((state: RootState) => state.toolRebarDetection.baseDiameter)
  const workingDistanceLabels = useSelector((state: RootState) => state.toolRebarDetection.workingDistanceLabels)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)

  /**
   * Update the base diameter of the rebar and any rebar that its diameter has not been set yet.
   */
  const updateDiameter = useCallback(
    (diameter: number) => {
      dispatch(setDiameter(diameter))
    },
    [dispatch],
  )

  /**
   * Only 1 working label can be selected at a time
   */
  useEffect(() => {
    const workingIds = workingDistanceLabels.map((label) => label.id)
    const selectedLabels = selectedElementIds.filter((id) => workingIds.includes(id))
    if (selectedLabels.length > 1) {
      dispatch(
        toggleSelectedElementIds({ ids: selectedLabels.slice(0, -1).map((labelId) => labelId), selected: false }),
      )
    }
  }, [selectedElementIds, workingDistanceLabels, dispatch])

  if (selectedTool !== EDITOR_TOOLS.CYLINDER) {
    return null
  }

  return (
    <CollapsePanel title="鉄筋の直径指定" type={PanelType.Tool} onChange={() => null}>
      <Box data-testid="property-panel" className="inner-container">
        <Menu variant="toolbar">
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            textAlign="left"
            fontWeight="normal"
            fontSize="xs"
            paddingX={2}
            height={7}
            width="100%"
            variant="toolbar"
            {...INPUT_GROUP_STYLES}
            borderColor={!baseDiameter ? 'red' : 'whiteAlpha.200'}
          >
            {baseDiameter ? `${findDiameterKeyByValue(baseDiameter)}（${baseDiameter || ''}）` : ''}
          </MenuButton>
          <Portal>
            <MenuList>
              {Object.keys(DIAMETERS).map((d) => (
                <MenuItem
                  key={d}
                  backgroundColor={baseDiameter === DIAMETERS[d] ? 'secondary.600' : 'transparent'}
                  onClick={() => updateDiameter(DIAMETERS[d])}
                  fontSize="xs"
                >
                  {d}（{DIAMETERS[d]}）
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    </CollapsePanel>
  )
}

export default DiameterPanel
