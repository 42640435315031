import { useMemo } from 'react'

import { VStack } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { useEditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, PanelType } from 'config/constants'

import { Mode } from '../../utils'
import EstimationMethod from './EstimationMethod'
import PlaneSideSelection from './PlaneSideSelection'

const VolumeSetupPanel = () => {
  // Context
  const { selectedTool, inspectionItems } = useEditorContext()

  // Store
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)
  const jobRunningTool = useSelector((state: RootState) => state.editor.jobRunningTool)

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.VOLUME_POLYGON, [selectedTool])
  const selectedVolumes = useMemo(
    () =>
      inspectionItems.filter(
        (item) => item.item_type === 'volume' && selectedElementIds.find((id) => item.shape_ids.polygons.includes(id)),
      ),
    [selectedElementIds, inspectionItems],
  )
  const selectedVolume = selectedVolumes[0]
  const mode = useMemo(() => {
    if (isToolSelected) return Mode.Modelling
    if (selectedVolume && selectedTool === EDITOR_TOOLS.MOVE && jobRunningTool === '') return Mode.Reevaluation
    return Mode.None
  }, [isToolSelected, selectedTool, selectedVolume, jobRunningTool])

  // Only for when volume is selected or tool is selected
  if (mode === Mode.None) {
    return null
  }

  return (
    <CollapsePanel title="体積の設定" type={PanelType.Tool} onChange={() => null}>
      <VStack data-testid="plane-side-selection-panel" className="inner-container">
        {isToolSelected ? <PlaneSideSelection /> : null}
        <EstimationMethod mode={mode} />
      </VStack>
    </CollapsePanel>
  )
}

export default VolumeSetupPanel
