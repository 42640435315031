import { ToolbarDistanceIcon } from 'assets/icons'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, USER_TYPES } from 'config/constants'

import { EditorTool, ToolbarCategory } from 'interfaces/editor'
import { PermissionSets } from 'interfaces/validation'

import useElementsPanel from './hooks/useElementsPanel'
import useMainCanvas from './hooks/useMainCanvas'

const CustomMeasurement: EditorTool = {
  key: EDITOR_TOOLS.CUSTOM_MEASUREMENT,
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) =>
    permissionSets.MAIN_CANVAS.MEASURE.includes(userType),
  toolbar: {
    icon: <ToolbarDistanceIcon width="50%" height="50%" />,
    label: EDITOR_TOOLS_LABELS.CUSTOM_MEASUREMENT,
    category: ToolbarCategory.Manipulate,
  },
  hooks: {
    useMainCanvas,
    useElementsPanel,
  },
}

export default CustomMeasurement
