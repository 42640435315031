import { useEffect, useMemo } from 'react'

import {
  Alert,
  Button,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import TutorialVideoModal from 'components/TutorialVideoModal'
import { forceOpenToolPanel } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ChevronDownIcon, HelpCircleIcon } from 'assets/icons'

import { useEditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { VolumeEstimationMethods } from 'interfaces/inspection'

import { Mode } from '../../utils'
import { setEstimationMethod, setRebarModelledConfirmed } from '../store'

const EstimationMethod = ({ mode }: { mode: Mode }) => {
  // Context
  const editorContext = useEditorContext()
  const { inspectionItems } = editorContext

  // Store
  const dispatch = useAppDispatch()
  const videos = useSelector((root: RootState) => root.videos.videos)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)
  const estimationMethod = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.estimationMethod)
  const rebarModelledConfirmed = useSelector(
    (state: RootState) => state.toolVolumeEstimationPolygon.rebarModelledConfirmed,
  )

  // Derived
  const selectedVolumes = useMemo(
    () =>
      inspectionItems.filter(
        (item) => item.item_type === 'volume' && selectedElementIds.find((id) => item.shape_ids.polygons.includes(id)),
      ),
    [selectedElementIds, inspectionItems],
  )
  const selectedVolume = selectedVolumes[0]
  const isSelectedMultiple = selectedVolumes.length > 1

  // Video modal
  const useDisclosureProps = useDisclosure()
  const videoConfig = useMemo(
    () =>
      videos.find(
        (video) => video.toolType === EDITOR_TOOLS.VOLUME_POLYGON && video.variant === 'volume-estimation-method',
      ),
    [videos],
  )

  /**
   * Changed selected method based on the selected volume or
   * set to Standard if user is on Volume tool.
   */
  useEffect(() => {
    if (mode === Mode.None) return

    if (mode === Mode.Modelling) {
      dispatch(setEstimationMethod(VolumeEstimationMethods.Standard))
      return
    }

    const method =
      selectedVolume?.volume?.is_flat_plane === false
        ? VolumeEstimationMethods.Advanced
        : VolumeEstimationMethods.Standard
    dispatch(setEstimationMethod(method))

    // If selected method is Standard but marked as not a valid method, open the panel
    if (method === VolumeEstimationMethods.Standard && selectedVolume?.volume?.is_valid_method === false) {
      dispatch(forceOpenToolPanel())
    }
  }, [selectedVolume, mode, dispatch])

  // Only for when volume is selected or tool is selected
  if (mode === Mode.None) {
    return null
  }

  return (
    <VStack width="100%">
      <Text fontSize="sm" color="white" alignSelf="start">
        体積測定方法
      </Text>

      <VStack width="95%" alignSelf="start" gap={3}>
        <HStack width="100%">
          <HelpCircleIcon size="1.4em" />
          <Button
            variant="link"
            whiteSpace="normal"
            textAlign="left"
            alignSelf="start"
            onClick={() => useDisclosureProps.onOpen()}
            fontSize="11px"
            borderBottom="1px dotted var(--chakra-colors-gray-500)"
            borderRadius={0}
            _hover={{
              textDecoration: 'none',
              borderBottom: '1px solid var(--chakra-colors-gray-300)',
              color: 'gray.300',
            }}
          >
            体積測定の詳細はこちら
          </Button>
        </HStack>

        {mode === Mode.Reevaluation && (
          <>
            {isSelectedMultiple && <Alert variant="panel-warning">1つの体積だけを選択してください。</Alert>}

            {selectedVolume?.volume?.is_flat_plane === true && selectedVolume?.volume?.is_valid_method === false && (
              <Alert variant="panel-info">
                斫り面が平坦ではないと検出しました。体積測定方法の設定を変更することで、精度が向上する可能性あります。
              </Alert>
            )}
          </>
        )}

        <Menu variant="panel" placement="bottom-end" gutter={4}>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="panel-dropdown">
            {estimationMethod}の測定方法
          </MenuButton>
          <Portal>
            <MenuList>
              <MenuItem onClick={() => dispatch(setEstimationMethod(VolumeEstimationMethods.Standard))}>
                {VolumeEstimationMethods.Standard}（平坦な斫り面）
              </MenuItem>
              <MenuItem onClick={() => dispatch(setEstimationMethod(VolumeEstimationMethods.Advanced))}>
                {VolumeEstimationMethods.Advanced}（平坦ではない斫り面）
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>

        {estimationMethod === VolumeEstimationMethods.Advanced && (
          <Checkbox
            isChecked={rebarModelledConfirmed}
            onChange={(e) => dispatch(setRebarModelledConfirmed(e.target.checked))}
            required
            width="100%"
          >
            <Text fontSize="xs">鉄筋がモデリング済み</Text>
          </Checkbox>
        )}
      </VStack>

      {videoConfig && <TutorialVideoModal useDisclosureProps={useDisclosureProps} {...videoConfig} />}
    </VStack>
  )
}

export default EstimationMethod
