import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  CursorState,
  setCursor,
  setDisablePanning,
  setDisableRotation,
  setIsDragging,
} from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Line3, Matrix4, Plane, Raycaster, Vector2, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { CircleAnchorIcon, DistanceLabelProps, PolygonPlaneMeshProps } from 'interfaces/canvas'
import { CanvasConfig } from 'interfaces/editor'
import { Polygon } from 'interfaces/shape'

import { getCentroidOfPolygon3d, getRotationDirection, projectToScreen } from 'services/Points'
import { findNormalTowardsB, getBoundingBoxFaces, sortRectanglePoints3D } from 'services/Shape'

import {
  SelectionStage,
  addPlanePoint,
  setPreviewPlanePoints,
  setSelectionStage,
  setTarget,
  undoLastPlanePoint,
  updateLastPlanePoint,
  updatePlanePoint,
} from '../store'

const raycaster = new Raycaster()

let previousScreenPos: { x: number; y: number } | null = null
let is2FingerTouched = false
let isMouseDown = false

const ROTATION_SENSITIVITY = 0.0025

export default function useMainCanvas(): CanvasConfig {
  // Context
  const { selectedTool, cameraRef, focusCameraDirectional } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const selectionStage = useSelector((state: RootState) => state.toolCameraProfile.selectionStage)
  const drawnPlanePoints = useSelector((state: RootState) => state.toolCameraProfile.drawnPlanePoints)
  const previewPlanePoints = useSelector((state: RootState) => state.toolCameraProfile.previewPlanePoints)
  const target = useSelector((state: RootState) => state.toolCameraProfile.target)
  const pcdBoundingBox = useSelector((state: RootState) => state.editor.pcdBoundingBox)

  // State
  const [onDownData, setOnDownData] = useState<{ position: number[]; timestamp: number } | undefined>() // screen position, not canvas coordinates.
  const [lastTouchPoint, setLastTouchPoint] = useState<PointArray>() // last 3D point touched by user
  const [plane, setPlane] = useState<Plane>()
  const [tempTarget, setTempTarget] = useState<Vector3>()
  const [movingAnchor, setMovingAnchor] = useState<number | undefined>()

  // Derived
  const pcdBoundingBoxFaces = useMemo(
    () =>
      pcdBoundingBox ? getBoundingBoxFaces(new Vector3(...pcdBoundingBox[0]), new Vector3(...pcdBoundingBox[1])) : null,
    [pcdBoundingBox],
  )

  // Vars
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.CAMERA_PROFILE, [selectedTool])

  /**
   * Handle mouse up event.
   *
   * @param e MouseEvent
   * @param point Clicked point in 3D Space
   */
  const onMouseUp = useCallback(
    (me?: React.MouseEvent<HTMLDivElement>, te?: React.TouchEvent<HTMLDivElement>, point?: PointArray) => {
      if (!isToolSelected || !point || (!me && !te)) return

      // Reset moving anchor state
      if (movingAnchor !== undefined) {
        dispatch(setCursor(CursorState.CROSSHAIR))
        setMovingAnchor(undefined)
        return
      }

      // ## User has been dragging the canvas validation, only for mouse interactions
      if (me || te) {
        const mouseStart = onDownData ? { ...onDownData } : undefined
        const mouseNow = previousScreenPos && te ? previousScreenPos : { x: me!.clientX, y: me!.clientY }
        setOnDownData(undefined)
        const distanceFromDown = mouseStart
          ? Math.hypot(mouseNow.x - mouseStart.position[0], mouseNow.y - mouseStart.position[1])
          : 0

        if (distanceFromDown > 10) return
      }

      // If we're on FOCUS stage, any click is a focus point change.
      if (selectionStage === SelectionStage.FOCUS) {
        if (!point || !plane || !cameraRef.current || is2FingerTouched) return

        // Step 1: Extract the old up vector from the original quaternion
        const oldUp = new Vector3(0, 1, 0).applyQuaternion(cameraRef.current.quaternion)

        // Step 2: Calculate the new right vector as the cross-product of forward and old up
        const right = new Vector3().crossVectors(plane.normal, oldUp).normalize()

        dispatch(setTarget(point))
        focusCameraDirectional(point, plane.normal, right, cameraRef.current.position.distanceTo(new Vector3(...point)))
      }
      // ## Draw stage only
      else if (selectionStage === SelectionStage.DRAW) {
        const isTouched = !!te
        if (drawnPlanePoints.length < 3) {
          dispatch(addPlanePoint({ point, isTouched }))
        } else if (drawnPlanePoints.length === 3) {
          dispatch(setSelectionStage(SelectionStage.ROTATE))
        }
      }
    },
    [
      onDownData,
      isToolSelected,
      drawnPlanePoints,
      selectionStage,
      plane,
      movingAnchor,
      cameraRef,
      dispatch,
      focusCameraDirectional,
    ],
  )

  /**
   * Handle mouse down event.
   */
  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isToolSelected) return
      setOnDownData({ position: [e.clientX, e.clientY], timestamp: Date.now() })
      isMouseDown = true

      if (selectionStage === SelectionStage.FOCUS) {
        dispatch(setCursor(CursorState.GRABBING))
        is2FingerTouched = e.button === 2
        previousScreenPos = { x: e.clientX, y: e.clientY }
      }
    },
    [isToolSelected, selectionStage, dispatch],
  )

  /**
   * Fine tune positioning of the camera.
   * Rotate camera around the target while being parallel on the plane.
   */
  const onFocusFineTune = useCallback(
    (screenPos: { x: number; y: number }) => {
      const canvasBox = document.getElementById('canvasBox') as HTMLElement

      if (!cameraRef.current || !target || !onDownData || !plane || !previousScreenPos || !canvasBox) return

      // Define some stuff for later
      const targetV3 = new Vector3(...target)
      const camera = cameraRef.current
      const right = new Vector3().crossVectors(camera.up, camera.position.clone().sub(targetV3)).normalize()

      // Get forward vector of the camera
      const forward = findNormalTowardsB(camera.position, targetV3, plane.normal)

      // ## Do panning
      if (is2FingerTouched) {
        raycaster.set(camera.position, forward)
        const result = raycaster.ray.intersectPlane(plane, new Vector3())

        if (result) setTempTarget(result)
        return
      }

      // ## Otherwise, do rotation

      // Get target in screen space
      const canvasRect = canvasBox.getBoundingClientRect()
      const targetScreen = projectToScreen(targetV3, camera, canvasRect.width, canvasRect.height)

      // if mouse is higher than the target, rotate in the opposite direction
      // also if mouse is on the left side of the target, rotate in the opposite direction
      const sign = getRotationDirection(
        new Vector2(targetScreen.x, targetScreen.y),
        new Vector2(previousScreenPos.x, previousScreenPos.y),
        new Vector2(screenPos.x, screenPos.y),
      )

      // calculate total distance travelled by mouse
      const delta =
        new Vector2(screenPos.x, screenPos.y).distanceTo(new Vector2(previousScreenPos.x, previousScreenPos.y)) * sign

      // Calculate the rotation angle based on delta movement and sensitivity
      const angle = delta * ROTATION_SENSITIVITY

      // Create a rotation matrix to rotate around the forward vector
      const rotationMatrix = new Matrix4().makeRotationAxis(forward, angle)

      // Apply the rotation to the right vector
      const newRight = right.clone().applyMatrix4(rotationMatrix).normalize()
      const newUp = new Vector3().crossVectors(newRight, forward).normalize()

      cameraRef.current.up.set(newUp.x, newUp.y, newUp.z)

      previousScreenPos = { x: screenPos.x, y: screenPos.y }
    },
    [cameraRef, target, plane, onDownData],
  )

  /**
   * Undo the last action.
   * 1. When drawing, go back a point.
   * 2. If completed, re-open (from ROTATE to DRAW stage).
   * 3. If on FOCUS stage, go back to ROTATE stage.
   */
  const onUndo = useCallback(() => {
    if (!isToolSelected) return

    if (selectionStage === SelectionStage.DRAW) {
      dispatch(undoLastPlanePoint())
    } else if (selectionStage === SelectionStage.ROTATE) {
      dispatch(setSelectionStage(SelectionStage.DRAW))
      dispatch(setPreviewPlanePoints([]))
      setPlane(undefined)
    } else if (selectionStage === SelectionStage.FOCUS) {
      dispatch(setSelectionStage(SelectionStage.ROTATE))
      dispatch(setDisablePanning(false))
      dispatch(setDisableRotation(false))
      dispatch(setCursor(CursorState.CROSSHAIR))
    }
  }, [isToolSelected, selectionStage, dispatch])

  /**
   * Handle moving of the anchor.
   */
  const onMoveAnchor = useCallback(
    (point: PointArray) => {
      if (!isToolSelected || movingAnchor === undefined) return

      dispatch(
        updatePlanePoint({
          index: movingAnchor,
          point,
        }),
      )
    },
    [isToolSelected, movingAnchor, dispatch],
  )

  /**
   * Once we have enough points, calculate the plane.
   */
  useEffect(() => {
    if (
      drawnPlanePoints.length < 3 ||
      selectionStage === SelectionStage.DRAW ||
      !pcdBoundingBox ||
      !pcdBoundingBoxFaces
    )
      return

    // make plane and find out plane that extends the entire PCD bounding box
    const vectorPoints = drawnPlanePoints.map((p) => new Vector3(...p))
    const pln = new Plane().setFromCoplanarPoints(vectorPoints[0], vectorPoints[1], vectorPoints[2])

    setPlane(pln)

    // find the edges that intersect with the plane
    const intersectedPoint: Vector3[] = []
    for (let i = 0; i < pcdBoundingBoxFaces.length; i += 1) {
      const points = pcdBoundingBoxFaces[i]

      for (let j = 0; j < points.length; j += 1) {
        const p1 = points[j]
        const p2 = points[(j + 1) % points.length]
        const line = new Line3(p1, p2)

        if (pln.intersectsLine(line)) {
          const intersect = new Vector3()
          pln.intersectLine(line, intersect)
          intersectedPoint.push(intersect)

          // only need 4 points
          if (intersectedPoint.length === 4) break
        }
      }

      // only need 4 points
      if (intersectedPoint.length === 4) break
    }

    if (intersectedPoint.length >= 4) {
      dispatch(setPreviewPlanePoints(sortRectanglePoints3D(intersectedPoint).map((p) => p.toArray())))
    }
  }, [drawnPlanePoints, pcdBoundingBoxFaces, selectionStage, pcdBoundingBox, movingAnchor, dispatch])

  /**
   * Once we enter FOCUS stage, set focus to the target.
   */
  useEffect(() => {
    if (selectionStage !== SelectionStage.FOCUS || !plane || !cameraRef.current) return

    // Calculate target point, should be center of what was drawn
    const tgt = getCentroidOfPolygon3d(drawnPlanePoints.map((p) => new Vector3(...p))).toArray()
    dispatch(setTarget(tgt))

    // Step 1: Extract the old up vector from the original quaternion
    const oldUp = new Vector3(0, 1, 0).applyQuaternion(cameraRef.current.quaternion)

    // Step 2: Calculate the new right vector as the cross-product of forward and old up
    const right = new Vector3().crossVectors(plane.normal, oldUp).normalize()

    focusCameraDirectional(tgt, plane.normal, right, cameraRef.current.position.distanceTo(new Vector3(...tgt)))
  }, [selectionStage, plane, drawnPlanePoints, cameraRef, focusCameraDirectional, dispatch])

  /**
   * Handle mouse up event on document level.
   * Mainly used by FOCUS stage to detect mouse up outside of canvas.
   */
  useEffect(() => {
    const mouseUp = () => {
      previousScreenPos = null
      isMouseDown = false

      // If we're on FOCUS stage, mouse up can mean done with dragging, so change cursor back to GRAB.
      if (selectionStage === SelectionStage.FOCUS) {
        dispatch(setCursor(CursorState.GRAB))
      }
    }

    document.addEventListener('mouseup', mouseUp)

    return () => {
      document.removeEventListener('mouseup', mouseUp)
    }
  }, [selectionStage, dispatch])

  /**
   * Anchor properties for the drawn plane.
   */
  const getAnchorProps = useCallback(
    (index: number, length: number): Partial<DistanceLabelProps> => {
      if (index === length - 1 && selectionStage === SelectionStage.DRAW && !lastTouchPoint) {
        return {
          hideBottomAnchor: true,
          topAnchorProps: {
            color: '#f00',
          },
        }
      }

      return {
        hideBottomAnchor: true,
        anchorProps: {
          outlineOpacity: 0,
          color: movingAnchor === index ? '#f00' : '#248CE4',
          scale: 1.3,
          icon: CircleAnchorIcon.MOVE,
          iconScale: 1.1,
          iconColor: 'white',
        },
        topAnchorProps:
          // No need for this on the last point as it follows the user anyway. Only for desktop though.
          index === length - 1 && selectionStage === SelectionStage.DRAW && !lastTouchPoint
            ? undefined
            : {
                tooltipText: '点を移動',
                /**
                 * When user holds down the anchor, initiate moving of the anchor.
                 * The reset of this state is done in onMouseUp().
                 */
                onDown: () => {
                  dispatch(setCursor(CursorState.GRABBING))
                  dispatch(setIsDragging(true)) // This is toggled off in MainCanvas::onMouseUp
                  setMovingAnchor(index)
                },
                onEnter: () => {
                  dispatch(setCursor(CursorState.GRAB))
                },
                onLeave: () => {
                  dispatch(setCursor(CursorState.CROSSHAIR))
                },
              },
      }
    },
    [selectionStage, lastTouchPoint, movingAnchor, dispatch],
  )

  /**
   * Drawn plane distance labels.
   * Also holds interaction events for moving the anchor.
   */
  const drawnPlaneLines = useMemo(
    () =>
      drawnPlanePoints.reduce<DistanceLabelProps[]>((all, point, index) => {
        if (!drawnPlanePoints[index]) return all

        all.push({
          ...getAnchorProps(index, drawnPlanePoints.length),
          id: `camera-focus-plane-points-${index}`,
          points: [point, drawnPlanePoints[(index + 1) % drawnPlanePoints.length]],
        })

        return all
      }, []),
    [drawnPlanePoints, getAnchorProps],
  )

  /**
   * Plane mesh for preview.
   */
  const previewPlaneMesh = useMemo((): PolygonPlaneMeshProps[] => {
    if (previewPlanePoints.length < 4 || selectionStage === SelectionStage.DRAW) return []

    return [
      {
        id: 'camara-profile-preview-plane',
        polygon: {
          shape_id: 'camara-profile-preview-plane',
          positions: previewPlanePoints,
          center: getCentroidOfPolygon3d(previewPlanePoints.map((p) => new Vector3(...p))).toArray(),
          vertices: previewPlanePoints.map((p) => [p[0], p[1]]),
          transformation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        } as Polygon,
        opacity: selectionStage === SelectionStage.FOCUS || movingAnchor !== undefined ? 0.25 : 0.5,
      },
    ]
  }, [previewPlanePoints, selectionStage, movingAnchor])

  return {
    objects: {
      distanceLabels: [SelectionStage.FOCUS, SelectionStage.SAVING].includes(selectionStage)
        ? []
        : [...drawnPlaneLines],
      polygonPlaneMeshes: [...previewPlaneMesh],
    },
    events: {
      onTouchStart: (ev, point) => {
        if (!isToolSelected) return
        is2FingerTouched = ev.touches.length > 1
        setLastTouchPoint(point)
        setOnDownData({
          position: [ev.touches[0].clientX, ev.touches[0].clientY],
          timestamp: Date.now(),
        })
        previousScreenPos = { x: ev.touches[0].clientX, y: ev.touches[0].clientY }
      },
      onTouchEnd: (ev) => {
        if (!isToolSelected) return
        onMouseUp(undefined, ev, lastTouchPoint)
      },
      onRawMouseUp: (e) => {
        if (!isToolSelected || e.button !== 2 || !tempTarget || selectionStage !== SelectionStage.FOCUS) return

        dispatch(setTarget(tempTarget.toArray()))
        setTempTarget(undefined)
      },
      onMouseUp: (ev, point) => {
        if (!isToolSelected) return

        // ignore if we have a touch point, we'll handle it in onTouchEnd
        if (lastTouchPoint) {
          setLastTouchPoint(undefined)
          return
        }

        onMouseUp(ev, undefined, point)
      },
      onMouseDown,
      onMove: useCallback(
        (
          point?: PointArray,
          me?: React.MouseEvent<HTMLDivElement, MouseEvent>,
          te?: React.TouchEvent<HTMLDivElement>,
        ) => {
          if (!isToolSelected) return

          const screenPos = te
            ? {
                x: te.touches[0].clientX,
                y: te.touches[0].clientY,
              }
            : { x: me!.clientX, y: me!.clientY }

          // Fine-tuning position of the camera
          if (selectionStage === SelectionStage.FOCUS && previousScreenPos && ((me && isMouseDown) || te)) {
            onFocusFineTune(screenPos)
            return
          }

          previousScreenPos = { x: screenPos.x, y: screenPos.y }

          // Beyond this, we need a point
          if (!point) return

          // Moving of an anchor
          if (movingAnchor !== undefined) {
            onMoveAnchor(point)
            return
          }

          if (te) {
            setLastTouchPoint(point)
          }

          // Beyond this is for mouse only
          if (selectionStage !== SelectionStage.DRAW || !me) return
          dispatch(updateLastPlanePoint(point))
        },
        [isToolSelected, selectionStage, movingAnchor, onMoveAnchor, onFocusFineTune, dispatch],
      ),
      onUndo,
    },
  }
}
