/* istanbul ignore file */
import { Dispatch, ReactElement, SetStateAction } from 'react'

import { InspectionArea as PDLInspectionArea } from 'project-dashboard-library/dist/interfaces/inspectionArea'
import { Matrix4Tuple, Vector2 } from 'three'

import { PointArray } from './attribute'
import { ErrorResponse } from './errors'
import { ShapeKeyType } from './shape'

/**
 * Pre-defined threshold keys. This must be in sync with the API.
 */
export type InspectionItemPreDefinedThresholdsKey = 'designed_value' | 'tolerance'

/**
 * Pre-defined threshold values
 */
export type InspectionItemPredefinedThresholds = { [name in InspectionItemPreDefinedThresholdsKey]?: number | null } // null only for FE, don't send null to API
export interface InspectionItemUserDefinedThresholds {
  /**
   * Unique key for the threshold
   */
  key?: string

  /**
   * Name of the threshold
   */
  name: string

  /**
   * Value of the threshold
   */
  value: string
}

export const INSPECTION_ITEM_TYPES = {
  PLANE_TO_CYLINDERS_DISTANCE: 'plane_to_cylinders_distance',
}

export interface ValuesEditModalConfig {
  /**
   * Title of the modal
   */
  title: string

  /**
   * Unit of the value
   */
  unit: string | ReactElement

  /**
   * Current value to be edited
   */
  value?: number

  /**
   * Type of the value
   */
  valueType?: 'text' | 'number'

  /**
   * Pre-defined threshold values
   */
  preDefinedThreshold?: InspectionItemPredefinedThresholds

  /**
   * User-defined threshold values
   */
  userDefinedThreshold?: InspectionItemUserDefinedThresholds[]

  /**
   * Inspection item id
   */
  inspectionSheetItemId?: string

  /**
   * Provide the name to be edited, if it were to be editable.
   * Skip this if the name is not editable.
   */
  name?: string
}

export interface InspectionSheetDiagram {
  image: string
  width: number
  height: number
}

export interface MainSheet {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void

  editingField: string
  setEditingField: (field: string) => void

  editingValue: string | Date
  setEditingValue: (value: string | Date) => void

  selectedItemIndex: number
  setSelectedItemIndex: (selectedItemIndex: number) => void

  itemResults: InspectionEvaluateResult[]
  setItemResults: (itemResults: InspectionEvaluateResult[]) => void

  setValuesEditingConfig: (value: ValuesEditModalConfig) => void

  loadingItems: { [itemId: string]: boolean }
  setLoadingItems: Dispatch<SetStateAction<{ [itemId: string]: boolean }>>

  updateItem: (updatedVolumeItem: InspectionItem) => Promise<void>
}

export interface File {
  name: string | null
  path: string | null
  size: number
}

/**
 * Arcball controls save state.
 */
export interface ArcballState {
  cameraFar: number
  cameraFov: number
  cameraMatrix: { elements: Matrix4Tuple }
  cameraNear: number
  cameraUp: { x: number; y: number; z: number }
  cameraZoom: number
  gizmoMatrix: { elements: Matrix4Tuple }
}
export interface CameraProfileState {
  arcballState: ArcballState
}

/**
 * Camera view orientation.
 */
export enum CameraViewOrientation {
  FRONT = 'front',
  BACK = 'back',
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

/**
 * Camera profile data to orient the camera.
 */
export interface CameraProfile {
  target: PointArray
  state: CameraProfileState
  positions: Record<CameraViewOrientation, PointArray>
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InspectionArea extends PDLInspectionArea {
  camera_profile?: CameraProfile

  /**
   * User-specified order of the inspection area
   */
  order?: number
}

export type InspectionShapeIds = Record<ShapeKeyType, string[]>

export interface InspectionItemRequestData {
  part_name: string
  need_update?: boolean
  pre_defined_thresholds?: InspectionItemPredefinedThresholds
  user_defined_thresholds?: InspectionItemUserDefinedThresholds[]
  is_shown_on_final_sheet?: boolean
}

export interface InspectionItemGridListDistance {
  name: string
  active: boolean
  distance: number | null
  position_on_grid_point: PointArray
  position_on_projected_point: PointArray
}

export interface InspectionItemGrid {
  estimated_value: number
  list_distances: InspectionItemGridListDistance[]
  min_grid_depth: number
  max_grid_depth: number
  intervals?: {
    long_axis: number
    short_axis: number
  }
}

export interface InspectionItem extends ErrorResponse, InspectionItemRequestData {
  partition_key?: string
  cover_distance?: InspectionItemNumberValues
  diameter?: InspectionItemNumberValues
  inspection_item_id?: string
  mean_distance?: InspectionItemNumberValues
  number?: InspectionItemNumberValues
  volume?: InspectionItemVolume
  plane_to_cylinders_distance?: InspectionItemNumberValues
  /**
   * @deprecated
   */
  plane_to_plane_distance?: InspectionItemNumberValues
  grid?: InspectionItemGrid
  length_with_distance_tool?: InspectionItemNumberValues
  polyline_length?: InspectionItemNumberValues
  situation?: string
  shape_ids: InspectionShapeIds
  item_type?: string
  volume_id?: string
  plane_side?: 'upper' | 'lower'
  polygon_area?: InspectionItemNumberValues
  polygon_perimeter?: InspectionItemNumberValues
  polygon_length_1?: InspectionItemNumberValues
  polygon_length_2?: InspectionItemNumberValues

  inspection_area_id?: string
  inspection_sheet_id?: string
}

export interface InspectionItemIndividualDistance {
  distance: number
  positions_for_distance: PointArray[]
}

export interface InspectionItemNumberValues {
  estimated_value?: number | null
  specified_value?: number | null
  positions_for_distance?: PointArray[]
  individual_distances?: InspectionItemIndividualDistance[]
}

export interface InspectionItemVolume extends InspectionItemNumberValues {
  is_flat_plane: boolean
  is_valid_method?: boolean
  nan_depth_ratio?: number
}

export interface InspectionSheet {
  partition_key?: string
  inspection_area_id?: string
  inspection_sheet_id: string
  construction_properties: {
    construction_project_name?: string
    construction_type?: string
    construction_type_detailed?: string
  }
  creator_name: string
  create_time_user_specified: string | null
}

export interface InspectionEvaluateResult {
  contractee: boolean
  contractor: boolean
}

export interface PlaneInspectionItems {
  area: InspectionItem | undefined
  perimeter: InspectionItem | undefined
  length1: InspectionItem | undefined
  length2: InspectionItem | undefined
}

export interface PolygonDiagramVertice {
  label: {
    text: string
    point: Vector2
  }
  point: Vector2
}

export enum VolumeEstimationMethods {
  Standard = '標準',
  Advanced = '高度',
}
