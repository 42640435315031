import { useCallback, useMemo, useState } from 'react'

import { Box, HStack, IconButton, Spacer, Text, VStack } from '@chakra-ui/react'
import { difference } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { DeleteIcon, HideIcon, ShowIcon } from 'assets/icons'

import { PanelType } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { EditorPanelItem, ElementsPanelConfig } from 'interfaces/editor'

import { setSelectedElementIds, toggleHiddenElementIds } from '../store/editor'
import { Tools } from '../tools/setup'
import CollapsePanel from './components/CollapsePanel'
import { Layer } from './components/Layer'
import useElementsPanel from './hooks/useElementsPanel'
import { getItemsByIds } from './utils'

export const ElementsPanel = () => {
  // Store
  const dispatch = useAppDispatch()
  const hiddenElementIds = useSelector((state: RootState) => state.editor.hiddenElementIds)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)
  const isElementDeleting = useSelector((state: RootState) => state.editor.isElementDeleting)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  // States
  const [isCollapsed, setIsCollapsed] = useState(false)

  // Hooks
  const { handleDelete } = useElementsPanel()

  // Derived
  const isInvisible = useMemo(
    () => !difference(selectedElementIds, hiddenElementIds).length,
    [selectedElementIds, hiddenElementIds],
  )

  // Initialize all tools
  const toolsObjectsPanel = Tools.map((tool) =>
    tool.hooks?.useElementsPanel ? tool.hooks.useElementsPanel() : undefined,
  ).filter((config) => !!config) as ElementsPanelConfig[]

  /**
   * Generate panel items for each tool
   */
  const toolsPanelItems = useMemo((): EditorPanelItem[] => {
    const getItem = (item: EditorPanelItem): EditorPanelItem => ({
      ...item,
      children: (item.children?.map((child) => getItem(child)) || []).concat(item.item ? getPanelItems(item) : []),
    })

    const getPanelItems = (parent?: EditorPanelItem) => {
      const parents = toolsObjectsPanel
        .map((hook) => {
          if (!hook) return undefined

          if (hook?.getPanelItems) {
            return hook.getPanelItems(parent?.item).map((item) => getItem(item))
          }

          return undefined
        })
        .flat()
        .filter(Boolean) as EditorPanelItem[]

      return parents
    }

    return getPanelItems()
  }, [toolsObjectsPanel])

  /**
   * Only non-working items can be batch deleted.
   */
  const canBatchDelete = useMemo(() => {
    const items = getItemsByIds(selectedElementIds, toolsPanelItems)
    const areAllWorkingItems = items.every((item) => item.isWorking)

    return selectedElementIds.length && !areAllWorkingItems
  }, [toolsPanelItems, selectedElementIds])

  /**
   * Every selected item can be batch hidden/shown.
   */
  const canBatchVisibility = useMemo(() => selectedElementIds.length > 0, [selectedElementIds])

  /**
   * Handle visibility toggle
   */
  const handleToggleVisibility = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()

      dispatch(toggleHiddenElementIds({ ids: selectedElementIds, invisible: !isInvisible }))
    },
    [selectedElementIds, isInvisible, dispatch],
  )

  return (
    <VStack flex={1} w="100%" gap={0} minH={0}>
      <Box data-testid="elements-panel" className="panel" flex={isCollapsed ? 1 : 0} minH={0}>
        <CollapsePanel
          title="要素一覧"
          type={PanelType.Elements}
          onChange={setIsCollapsed}
          preCollapse={
            <>
              {/* Batch operation */}
              <HStack
                w="100%"
                px={2}
                py={1}
                backgroundColor="gray.800"
                borderBottomColor="gray.900"
                borderBottomWidth={1}
                cursor="pointer"
                onClick={() => {
                  dispatch(setSelectedElementIds([]))
                }}
                position="sticky"
                top={0}
                left={0}
                zIndex={10}
              >
                <Text flex={1} whiteSpace="nowrap">
                  {canBatchDelete ? '選択した要素' : '選択要素なし '}
                </Text>
                <Spacer />
                <IconButton
                  icon={isInvisible && canBatchVisibility ? <HideIcon /> : <ShowIcon />}
                  aria-label="hide"
                  opacity={!canBatchVisibility ? 0.5 : 1}
                  cursor={!canBatchVisibility ? 'not-allowed' : 'pointer'}
                  variant="panel-icon"
                  onClick={handleToggleVisibility}
                />
                {!isElementDeleting && isAllowedToModify ? (
                  <IconButton
                    aria-label="delete"
                    icon={<DeleteIcon />}
                    opacity={!canBatchDelete ? 0.5 : 1}
                    cursor={!canBatchDelete ? 'not-allowed' : 'pointer'}
                    variant="panel-icon"
                    onClick={(e) => {
                      e.stopPropagation()
                      return handleDelete(selectedElementIds, toolsPanelItems)
                    }}
                  />
                ) : null}
              </HStack>
            </>
          }
        >
          <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1}>
            {/* Object list */}
            {toolsPanelItems.map((item) => (
              <Layer key={item.key} item={item} depth={0} toolsPanelItems={toolsPanelItems} />
            ))}
          </VStack>
        </CollapsePanel>
      </Box>

      <Spacer flex={!isCollapsed ? 1 : 0} />
    </VStack>
  )
}
