import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorPanelItem, ElementsPanelConfig } from 'interfaces/editor'
import { InspectionItem } from 'interfaces/inspection'

import { zeroPad } from 'services/Util'

import { deleteTempMeasurement } from '../store'
import { isWorking } from '../utils'

const useElementsPanel = (): ElementsPanelConfig => {
  // Store
  const dispatch = useAppDispatch()
  const customMeasurements = useSelector((state: RootState) => state.toolCustomMeasurement.measurements)
  const tempMeasurements = useSelector((state: RootState) => state.toolCustomMeasurement.tempMeasurements)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Flags
  const isAllowedToDetect = permissionSet.DETECT.includes(userType)

  return {
    getPanelItems: useCallback(
      (parent?: EditorPanelItem['item']): EditorPanelItem[] => {
        // Custom measurement is parent-level, ignore if parent is provided
        if (parent) return []

        if (customMeasurements.length || tempMeasurements.length) {
          return [
            {
              key: 'panel-custom-measurements',
              label: '距離',
              isVirtualContainer: true,
              isCountable: true,
              children: customMeasurements
                .map<EditorPanelItem>((measurement, index) => ({
                  key: `panel-custom-measurement-${measurement.inspection_item_id}`,
                  label: measurement?.part_name || `距離${zeroPad(index + 1, 3)}`,
                  item: measurement as InspectionItem,
                  isWorking: isAllowedToDetect && isWorking(measurement.inspection_item_id),
                  isSaving: isAllowedToDetect && isWorking(measurement.inspection_item_id),
                  onDelete: isAllowedToDetect && !isWorking(measurement.inspection_item_id),
                }))
                .concat(
                  tempMeasurements.map((measurement, index) => ({
                    key: `panel-custom-measurement-${measurement.inspection_item_id}`,
                    label: `距離${zeroPad(customMeasurements.length + index + 1, 3)}`,
                    item: measurement as InspectionItem,
                    onDelete: () => {
                      dispatch(deleteTempMeasurement(measurement.inspection_item_id!))
                    },
                  })),
                ),
            },
          ]
        }

        return []
      },
      [customMeasurements, tempMeasurements, isAllowedToDetect, dispatch],
    ),
  }
}

export default useElementsPanel
