import { useCallback } from 'react'

import { setHoveredElementId } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { useEditorContext } from 'contexts/Editor'

import { PLANE_SIDE_LABELS } from 'config/constants'

import { EditorPanelItem, ElementsPanelConfig } from 'interfaces/editor'
import { PlaneSide } from 'interfaces/shape'

import { getVolumeEstimationItems } from 'services/VolumeEstimation'

import {
  PANEL_WORKING_PLANE_LOWER_ID,
  PANEL_WORKING_PLANE_UPPER_ID,
  resetBottomPlane,
  resetTopPlane,
  resetWorkingPoints,
} from '../store'

const useElementsPanel = (): ElementsPanelConfig => {
  const { inspectionItems, shapes } = useEditorContext()

  // Store
  const dispatch = useAppDispatch()
  const planes = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.planes)
  const planeCreationOrder = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.planeCreationOrder)
  const polygonWorkingPoints = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.workingPoints)
  const isDirty = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.isDirty)
  const isLoading = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.isSaving)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  return {
    getPanelItems: useCallback(
      (parent?: EditorPanelItem['item']): EditorPanelItem[] => {
        // Volume is parent-level item, ignore if a parent is provided
        if (parent) return []

        const volumes = getVolumeEstimationItems(inspectionItems)

        if (volumes.length === 0 && !isDirty) return []

        return [
          {
            key: 'panel-volume',
            label: '体積',
            isVirtualContainer: true,
            isCountable: true,
            children: [
              // Saved volumes
              ...volumes.map<EditorPanelItem>((item, index) => ({
                key: `panel-volume-${item.inspection_item_id!}`,
                label: item.part_name || `体積 ${index + 1}`,
                item,
                isCountable: false,
                isVirtualContainer: true,
                onDelete: isAllowedToModify
                  ? shapes.polygons
                      .filter((shape) => item.shape_ids.polygons.includes(shape.shape_id))
                      .map((shape) => shape.shape_id)
                  : false,
                children: [
                  {
                    key: `panel-volume-${item.inspection_item_id!}-planes`,
                    label: '平面',
                    isCountable: false,
                    toggleVisibility: true,
                    isVirtualContainer: true,
                    defaultCollapsed: true,
                    onHover: (hovered) => {
                      dispatch(setHoveredElementId(hovered ? item.inspection_item_id! : ''))
                    },
                    children: [
                      {
                        key: `panel-volume-${item.inspection_item_id}-upper`,
                        label: PLANE_SIDE_LABELS.upper,
                        item: shapes.polygons.find(
                          (shape) =>
                            item.shape_ids.polygons.includes(shape.shape_id) && shape.plane_side === PlaneSide.UPPER,
                        ),
                        isCountable: false,
                        onDelete: false,
                      },
                      {
                        key: `panel-volume-${item.inspection_item_id}-lower`,
                        label: PLANE_SIDE_LABELS.lower,
                        item: shapes.polygons.find(
                          (shape) =>
                            item.shape_ids.polygons.includes(shape.shape_id) && shape.plane_side === PlaneSide.LOWER,
                        ),
                        isCountable: false,
                        onDelete: false,
                      },
                    ],
                  },
                ],
              })),

              // Working planes
              isDirty
                ? {
                    key: 'panel-volume-working-planes',
                    label: `体積 ${volumes.length + 1}`,
                    isCountable: false,
                    isWorking: true,
                    isSaving: isLoading,
                    toggleVisibility: !planes[planeCreationOrder[0]] && polygonWorkingPoints.length ? null : true,
                    children: [
                      {
                        key: 'panel-volume-working-planes',
                        label: '平面',
                        isCountable: false,
                        toggleVisibility: !planes[planeCreationOrder[0]] && polygonWorkingPoints.length ? null : true,
                        children: [
                          !planes[planeCreationOrder[0]] && polygonWorkingPoints.length
                            ? {
                                key: 'panel-volume-working-plane-drawing',
                                label: PLANE_SIDE_LABELS[planeCreationOrder[0]],
                                isContainer: false,
                                toggleVisibility: null,
                                isSaving: isLoading,
                                isWorking: true,
                                onDelete: isLoading ? false : () => dispatch(resetWorkingPoints()),
                              }
                            : null,
                          planes.upper
                            ? {
                                key: PANEL_WORKING_PLANE_UPPER_ID,
                                label: PLANE_SIDE_LABELS.upper,
                                isContainer: false,
                                toggleVisibility: true,
                                onDelete:
                                  (!!planes.lower && planeCreationOrder[0] === PlaneSide.UPPER) || isLoading
                                    ? false
                                    : () => dispatch(resetTopPlane()),
                              }
                            : null,
                          planes.lower
                            ? {
                                key: PANEL_WORKING_PLANE_LOWER_ID,
                                label: PLANE_SIDE_LABELS.lower,
                                isContainer: false,
                                toggleVisibility: true,
                                onDelete:
                                  (!!planes.upper && planeCreationOrder[0] === PlaneSide.LOWER) || isLoading
                                    ? false
                                    : () => dispatch(resetBottomPlane()),
                              }
                            : null,
                        ].filter(Boolean),
                      },
                    ],
                  }
                : null,
            ].filter(Boolean) as EditorPanelItem[],
          },
        ]
      },
      [
        inspectionItems,
        shapes,
        planeCreationOrder,
        planes,
        isDirty,
        polygonWorkingPoints.length,
        isLoading,
        isAllowedToModify,
        dispatch,
      ],
    ),
  }
}

export default useElementsPanel
