/* istanbul ignore file */
import { InputProps } from '@chakra-ui/react'

export const NAV_BAR_HEIGHT = 60
export const CYLINDER_PANEL_WIDTH = 250

export const TOAST_CONFIG = {
  title: '',
  status: 'success',
  duration: 3000,
  isClosable: true,
  position: 'top',
} as const

// default breakpoints
//   sm:  30em
//   md:  48em
//   lg:  62em
//   xl:  80em
//   2xl: 96em
export const CONTAINER_MAX_WIDTH = { lg: '58em', xl: '74em', '2xl': '89em' }

export const INFO_PANEL_PADDING = 2 // * 4px
export const INFO_PANEL_WIDTH = '250px'
export const LAYER_ITEM_STYLES = {
  borderColor: 'transparent',
  borderWidth: '1px',
  _hover: {
    borderColor: 'whiteAlpha.50',
  },
}
export const INPUT_GROUP_STYLES = {
  backgroundColor: 'gray.700',
  borderColor: 'whiteAlpha.200',
  borderRadius: 'md',
  borderWidth: '1px',
}

export const EDITOR_POINT_COLOR = 'red'
export const EDITOR_NEUTRAL_POINT_COLOR = 'gray'
export const EDITOR_FRAME_COLORS = {
  cylinders: 'red',
  polygons: 'blue',
  distance: 'red',
  cuboid: 'red',
  detectedPlaneToCylinderDistance: '#ff0000',
  detectedCylinderToPlaneDistance: '#FFA500',
}

export const EDITOR_ACTION_BUTTON_MIN_WIDTH = 106
export const EDITOR_DEFAULT_BACKGROUND = '#000'

export const INSPECTION_CONTRACTEE_ERROR_COLOR = 'red'
export const INSPECTION_CONTRACTOR_ERROR_COLOR = 'orange'
export const INSPECTION_NORMAL_COLOR = 'secondary'

const NOTIFICATION_BOX_COMMON_STYLES = {
  target: '_blank',
  size: 'sm',
  fontSize: 'xs',
  outline: 'none',
  _focus: { boxShadow: 'none' },
}
export const NOTIFICATION_STYLES = {
  info: {
    box: { bgGradient: 'linear(to-r, cyan.700, purple.500)' },
    text: { color: 'whiteAlpha.900', fontWeight: 'semibold', align: 'center' as const },
    button: {
      bgColor: 'blackAlpha.300',
      color: 'whiteAlpha.900',
      _hover: { bgColor: 'blackAlpha.300', textDecoration: 'none' },
      _active: { bgColor: 'blackAlpha.300' },
      ...NOTIFICATION_BOX_COMMON_STYLES,
    },
  },
  warning: {
    box: { bgGradient: 'linear(to-r, yellow.400, orange.300)' },
    text: { color: '', fontWeight: 'semibold', align: 'center' as const },
    button: {
      color: '',
      bgColor: 'whiteAlpha.700',
      _hover: { bgColor: 'whiteAlpha.700', textDecoration: 'none' },
      _active: { bgColor: 'whiteAlpha.700' },
      ...NOTIFICATION_BOX_COMMON_STYLES,
    },
  },
  error: {
    box: { bgColor: 'red.600' },
    text: { color: 'whiteAlpha.900', fontWeight: 'semibold', align: 'center' as const },
    button: {
      color: 'whiteAlpha.900',
      bgColor: 'blackAlpha.300',
      _hover: { bgColor: 'blackAlpha.300', textDecoration: 'none' },
      _active: { bgColor: 'blackAlpha.300' },
      ...NOTIFICATION_BOX_COMMON_STYLES,
    },
  },
}

export const BLUEPRINT_INPUT_STYLES: InputProps = {
  backgroundColor: 'transparent',
  borderColor: 'whiteAlpha.200',
  borderRadius: 'md',
  height: 6,
  px: 2,
  size: 'sm',
  textAlign: 'center',
  width: '0',
}

export const PLANE_SIDE_COLOR = Object.freeze({
  default: '#0077ff',
  lower: '#dd6b20',
  upper: '#33a3ff',
})

export const PLANE_OPACITY = Object.freeze({
  STANDARD: 0.25,
  HOVER: 0.7,
  SELECTED: 0.7,
  DIMMED: 0.1,
})

export const STANDALONE_PLANE_OPACITY = Object.freeze({
  STANDARD: 0.9,
  HOVER: 1,
  SELECTED: 1,
  DIMMED: 0.5,
})

export const PLANE_BORDER_THICKNESS = Object.freeze({
  STANDARD: 0.0015,
  SELECTED: 0.004,
})
