/* istanbul ignore file */

/**
 * Interfaces related to the editor.
 */
import React, { ReactElement } from 'react'

import { Points } from 'three'

import { USER_TYPES } from 'config/constants'

import { Identifiable, LayerStatus, PointArray } from './attribute'
import {
  CircleAnchorProps,
  CylinderMeshProps,
  DistanceLabelProps,
  Editor,
  LabelProps,
  PolygonPlaneMeshProps,
  PolygonPlaneMeshTransformableProps,
} from './canvas'
import { InspectionItem } from './inspection'
import { Cuboid, Shape, ShapeKeyType } from './shape'
import { PermissionSets } from './validation'

/**
 * Extra properties/flags for editor events.
 */
export interface CanvasEventsExtra {
  /**
   * User has clicked on the same point twice.
   */
  isDoubleClicked: boolean

  /**
   * User has clicked on the same point 3 times.
   */
  isTripleClicked: boolean

  /**
   * User has been dragging the canvas.
   */
  isDragged: boolean
}

/**
 * All events that could occur on the canvas.
 */
export interface CanvasEvents {
  /**
   * Mouse up event from the canvas.
   *
   * @param e MouseEvent
   * @param points Clicked point in 3D Space
   * @param extra Extra properties/flags
   */
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, points: PointArray | undefined) => void

  /**
   * Raw mouse up event from the canvas.
   * Since this runs before the onMouseUp event, it will not have any extra properties (double click detection, etc)
   * or any click validation.
   *
   * Generally you do not want to use this event, use onMouseUp instead.
   *
   * Note that having this does not prevent onMouseUp from running.
   *
   * @param e MouseEvent
   * @param points Clicked point in 3D Space
   * @param extra Extra properties/flags
   */
  onRawMouseUp?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, points: PointArray | undefined) => void

  /**
   * Mouse up event from the canvas.
   * This is only triggered with left click or touch. To handle right click, use onRawMouseUp.
   *
   * Note that having this does not prevent onRawMouseUp from running.
   *
   * @param e MouseEvent
   * @param points Clicked point in 3D Space
   * @param extra Extra properties/flags
   */
  onMouseUp?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    points: PointArray | undefined,
    extra: CanvasEventsExtra,
  ) => void

  /**
   * Move event from the canvas (mouse or touch).
   *
   * @param points Clicked point in 3D Space
   * @param mouseEvent MouseEvent
   * @param touchEvent TouchEvent
   * @param extra Extra properties/flags
   */
  onMove?: (
    points: PointArray | undefined,
    mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    touchEvent?: React.TouchEvent<HTMLDivElement>,
    extra?: CanvasEventsExtra,
  ) => void

  /**
   * Touch start event from the canvas.
   *
   * @param points Clicked point in 3D Space
   */
  onTouchStart?: (touchEvent: React.TouchEvent<HTMLDivElement>, points: PointArray | undefined) => void

  /**
   * Touch move event from the canvas.
   *
   * @param points Current point in 3D Space
   */
  onTouchMoveCapture?: (touchEvent: React.TouchEvent<HTMLDivElement>, points: PointArray | undefined) => void

  /**
   * Touch end event from the canvas. End touch events does not provide location of the touch.
   * Use the last known location from the onTouchMove event.
   */
  onTouchEnd?: (touchEvent: React.TouchEvent<HTMLDivElement>, extra: CanvasEventsExtra) => void

  /**
   * User initiated undo through keyboard shortcut or button.
   */
  onUndo?: () => void
}

/**
 * Objects to be shown on the canvas.
 */
export interface CanvasConfigObjects {
  /**
   * Distance labels to be shown on the canvas.
   */
  distanceLabels?: DistanceLabelProps[]

  /**
   * Warning labels to be shown on the canvas.
   */
  labels?: LabelProps[]

  /**
   * Circle anchors to be shown on the canvas.
   */
  circleAnchors?: CircleAnchorProps[]

  /**
   * Polygon plane mesh to be shown on the canvas.
   */
  polygonPlaneMeshes?: PolygonPlaneMeshProps[]

  /**
   * Cylinder mesh to be shown on the canvas.
   */
  cylinderMeshes?: CylinderMeshProps[]

  /**
   * Transformable polygon plane mesh to be shown on the canvas.
   */
  polygonPlaneMeshTransformable?: PolygonPlaneMeshTransformableProps[]
}

/**
 * Configuration for the canvas.
 */
export interface CanvasConfig {
  events?: CanvasEvents
  objects?: CanvasConfigObjects
}

/**
 * Props to be accepted by tool hooks.
 */
export interface CanvasProps {
  pointCloud: Points | undefined
}

/**
 * Events for editor buttons.
 */
export interface EditorButtonEvents {
  /**
   * Click event handler.
   */
  onClick: () => void
}

/**
 * Editor button congfiguration.
 */
export interface EditorButton extends EditorButtonEvents {
  /**
   * Unique key of this button. It must be unique across all tools.
   */
  key?: string

  /**
   * Label of the button.
   */
  label?: string

  /**
   * Label to be shown when the action is in progress.
   */
  loadingLabel?: string

  /**
   * Tooltip to be shown when hovering over the button.
   */
  tooltip?: string | ReactElement

  /**
   * Custom icon to be shown on the button.
   * Defaults to the type of the button.
   */
  icon?: ReactElement

  /**
   * Indicates the tool is processing.
   */
  isLoading?: () => boolean

  /**
   * Whether the button should be shown.
   */
  isShown: () => boolean

  /**
   * Whether the button should be disabled.
   */
  isDisabled: () => boolean
}

/**
 * Buttons to be shown on the bottom-right corner of the canvas.
 */
export interface EditorButtons {
  /**
   * Trigger the save function of the current tool.
   */
  submit?: EditorButton

  /**
   * Completely reset the current tool. It should set the state back to the initial state.
   */
  reset?: EditorButton

  /**
   * Undo the last action.
   * It does not have any events since it is handled by the editor itself
   * and propagated to event hook in useMainCanvas.
   */
  undo?: Omit<EditorButton, keyof EditorButtonEvents>
}

export interface EditorPanelItem {
  /**
   * Unique key of the item.
   */
  key: string

  /**
   * Label to be shown on the item.
   */
  label: string | JSX.Element | ReactElement

  /**
   * Dropdown to be shown on the item.
   * If this is shown, all icons including delete button will be hidden.
   * Only visibility toggle will be shown.
   */
  dropdown?: ReactElement

  /**
   * Item to be shown.
   */
  item?: Identifiable | (InspectionItem & LayerStatus) | Shape | Cuboid

  /**
   * Children items.
   */
  children?: EditorPanelItem[]

  /**
   * Will show a count badge on the item.
   */
  isCountable?: boolean

  /**
   * Will show a working indicator on the item.
   */
  isWorking?: boolean

  /**
   * Will show a saving indicator on the item.
   */
  isSaving?: boolean

  /**
   * Virtual container will behave the same as non-virtual container as far
   * as interactions on the parent that will affect those children.
   * However bubbling up, interactions on the children will not be the same.
   *
   * 1. Auto-toggle of parent
   *   - On non-virtual container, toggling all child will not toggle the parent.
   *   - On virtual container, toggling all child will toggle the parent.
   * 2. Deletion
   *   - On non-virtual container, it will delete the parent only. BE is responsible for deleting children.
   *   - On virtual container, it will collect children IDs and delete them.
   */
  isVirtualContainer?: boolean

  /**
   * If it has children, it will be collapsed by default.
   */
  defaultCollapsed?: boolean

  /**
   * Custom item deletion event.
   * If it is false, the item will not be deletable.
   */
  onDelete?: (() => Promise<void>) | (() => void) | string[] | boolean

  /**
   * Custom item selection event.
   * If it is false, the item will not be selectable.
   */
  onSelect?: (() => void) | false

  /**
   * Custom item hover even
   * t.
   * @param flag Mouse in or out.
   */
  onHover?: (flag: boolean) => void

  /**
   * Visibility toggle configuration. By default any panel item with `item` set
   * can be toggled so there's no need to set this.
   * - If it is FALSE, item visibility will not be togglable.
   * - If it is TRUE + isVirtualContainer, it will toggle all children.
   * - If it is NULL, it will display but not interactable.
   * - If it is an array, it will be used as a list of keys to toggle.
   * - If it is an object, it will treated as custom configuration.
   *   - This is **HIGHLY** discouraged and should only be used for special cases.
   */
  toggleVisibility?: { invisible: boolean; onToggle: () => void } | string[] | boolean | null
}

export interface EditorConfig {
  buttons?: EditorButtons
}

export enum ToolbarCategory {
  None,
  Navigate,
  Generate,
  Manipulate,
  Help,
}

export interface ElementsPanelConfig {
  getPanelItems?: (parent?: EditorPanelItem['item']) => EditorPanelItem[]
}

/**
 * Definition of a tool to be used by Editor.
 */
export interface EditorTool {
  /**
   * Tool key (must be from EDITOR_TOOLS constant)
   */
  key: string

  /**
   * Check if the tool is allowed for the user.
   *
   * @param permissionSets User's permission sets.
   * @param userType User's type.
   * @returns
   */
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) => boolean

  /**
   * Toolbar-related configurations
   */
  toolbar?: {
    /**
     * Tool icon. Must be a React component.
     * Define it first in assets/icons.tsx
     */
    icon: ReactElement

    /**
     * Tool label. Used for tooltip when hovering over the tool.
     */
    label: string

    /**
     * Tool variants configuration. Usually used for multiple tools under the same category.
     */
    variants?: EditorTool[]

    /**
     * Tool category. Used to group tools in the toolbar.
     * Default is ToolbarCategory.None.
     */
    category?: ToolbarCategory
  }

  /**
   * Tool hooks
   */
  hooks?: {
    /**
     * Hook to be executed by Editor.tsx
     * Usually used to define components to be shown on the canvas.
     * Context value is passed directly here since the hook is run _inside_ Editor.tsx
     * itself. The context has not been set yet, so useContext() will not work.
     */
    useEditor?: (contextValue: Editor) => EditorConfig

    /**
     * Hook for MainCanvas.
     * Most behavior will defined in this hook.
     *
     * @param props
     */
    useMainCanvas?: (props: CanvasProps) => CanvasConfig

    /**
     * Hook to return items for the objects panel.
     * When generating items at parent-level, make sure to check if the parent is undefined
     * and only return the items at this time.
     *
     * @param parent Parent item. If the item is a child, this will be the parent item.
     *               If the item is a parent, this will be undefined.
     *               Check EditorPanelItem['item'] for allowed types, but generally
     *               it must extend LayerStatus.
     */
    useElementsPanel?: () => ElementsPanelConfig
  }

  /**
   * Components to be added to various parts of the editor.
   */
  components?: {
    /**
     * Components to be added on the editor. This will be added outside of the canvas.
     * Use this to add modals, etc.
     * Must be a React component.
     */
    editor?: React.FC[]

    /**
     * Components to be added on the canvas.
     * Must be a THREE.js component.
     */
    canvas?: React.FC[]

    /**
     * Component to be added on the submission panel.
     * This panel will appear just before the submission button.
     * A tool can only have 1 panel at a time. If a tool has multiple panels for different states,
     * change the panel based on the state within the panel itself.
     */
    panel?: React.FC
  }

  /**
   * Configuration for the tool.
   */
  config?: {
    /**
     * Additional toolbars to be shown.
     */
    additionalToolbars?: {
      /**
       * Whether to use cuboid controls.
       */
      cuboidControls?: boolean
    }

    /**
     * Volume configuration.
     */
    volume?: {
      /**
       * Whether the tool requires a volume to be created.
       */
      required?: boolean

      /**
       * If volume can be selected.
       */
      selectable?: boolean

      /**
       * If only one volume can be selected.
       */
      onlyOneSelectable?: boolean
    }

    cylinder?: {
      /**
       * Whether the tool requires a cylinder to be created.
       */
      required?: boolean
    }

    /**
     * Config for selecting/placing anchors.
     */
    anchor?: {
      /**
       * Whether anchor can be placed on objects.
       * By default, anchors can be placed on objects.
       */
      notPlaceableOnObjects?: boolean
    }

    /**
     * Type of shapes that can be selected by this tool.
     */
    selectableShapes?: ShapeKeyType[]
  }
}
