import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import { toggleSelectedElementIds } from 'pages/projects/editor/store/editor'
import { useAppDispatch } from 'store/app'

import { ChevronDownIcon } from 'assets/icons'

import { DIAMETERS } from 'config/constants'
import { INPUT_GROUP_STYLES } from 'config/styles'

import { findDiameterKeyByValue } from 'services/Util'

import { DistanceLabelWorkingCylinderProps, updateDistanceLabelById } from '../store'

const DiameterSelection = ({ label }: { label: DistanceLabelWorkingCylinderProps }) => {
  // Store
  const dispatch = useAppDispatch()

  return (
    /* A wrapper container specifically for preventing bubbling up click events */
    <Box flex={1} onClick={(e) => e.stopPropagation()}>
      <Menu variant="toolbar">
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          textAlign="left"
          fontWeight="normal"
          fontSize="xs"
          paddingX={2}
          marginLeft={1}
          height={7}
          width="100%"
          variant="toolbar"
          {...INPUT_GROUP_STYLES}
        >
          {label.diameter ? `${findDiameterKeyByValue(label.diameter)}（${label.diameter || ''}）` : ''}
        </MenuButton>
        <Portal>
          <MenuList>
            {Object.keys(DIAMETERS).map((d) => (
              <MenuItem
                key={d}
                backgroundColor={label.diameter === DIAMETERS[d] ? 'secondary.600' : 'transparent'}
                onClick={() => {
                  dispatch(updateDistanceLabelById({ id: label.id, diameter: DIAMETERS[d] }))
                  dispatch(toggleSelectedElementIds({ ids: [label.id], selected: false }))
                }}
                fontSize="xs"
              >
                {d}（{DIAMETERS[d]}）
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )
}

export default DiameterSelection
