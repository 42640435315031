import { FC, PropsWithChildren, ReactElement, useEffect } from 'react'

import { Box, Button, Collapse, Flex, useDisclosure } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { BigChevronRightIcon as ChevronRightIcon } from 'assets/icons'

import { PanelType } from 'config/constants'

import { toggleCollapsedPanel } from '../../store/editor'

const CollapsePanel: FC<
  PropsWithChildren & {
    title: string
    type: PanelType
    onChange: (isOpen: boolean) => void
    preCollapse?: ReactElement
  }
> = ({ children, title, type, preCollapse, onChange }) => {
  // Store
  const dispatch = useAppDispatch()
  const collapsedPanel = useSelector((state: RootState) => state.editor.collapsedPanel)

  // Disclosure
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure({ defaultIsOpen: !collapsedPanel.includes(type) })

  /**
   * Toggle panel based on store
   */
  useEffect(() => {
    const toOpen = !collapsedPanel.includes(type)

    if (toOpen === isOpen) return

    if (toOpen) {
      onOpen()
    } else {
      onClose()
    }
  }, [onClose, onOpen, isOpen, collapsedPanel, type])

  /**
   * Callback for panel state change
   */
  useEffect(() => {
    onChange(isOpen)
  }, [isOpen, onChange])

  return (
    <Flex w="100%" maxH="100%" flex={1} flexDirection="column" className={isOpen ? 'opened' : 'collapsed'}>
      <Button
        onClick={() => {
          onToggle()
          dispatch(toggleCollapsedPanel(type))
        }}
        w="100%"
        variant="layer"
        rightIcon={<ChevronRightIcon />}
        paddingInlineStart={0}
        className="panel-header"
      >
        {title}
      </Button>
      <Box overflowX="hidden" overflowY="auto" position="relative">
        {preCollapse}
        <Collapse in={isOpen} animateOpacity data-testid="panel-contents">
          {children}
        </Collapse>
      </Box>
    </Flex>
  )
}

export default CollapsePanel
