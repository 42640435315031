/* istanbul ignore file */
import { alertAnatomy, menuAnatomy, tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react'
import { merge } from 'lodash'
import PDLTheme from 'project-dashboard-library/dist/config/theme'

import { INFO_PANEL_PADDING } from './styles'

const alertHelper = createMultiStyleConfigHelpers(alertAnatomy.keys)
const menuHelper = createMultiStyleConfigHelpers(menuAnatomy.keys)
const tabsHelper = createMultiStyleConfigHelpers(tabsAnatomy.keys)

const Button = {
  sizes: {
    md: {
      fontSize: 'inherit',
    },
  },
  variants: {
    outlinePrimary: {
      borderWidth: '1px',
      borderColor: 'primary.500',
      color: 'primary.500',
      _hover: {
        backgroundColor: 'gray.50',
      },
    },
    outlineStatic: {
      borderWidth: '1px',
      borderColor: 'white',
      color: 'white',
      _hover: {
        backgroundColor: 'white',
        color: 'primary.500',
      },
    },
    outlineStaticInvert: {
      borderWidth: '1px',
      borderColor: 'gray.200',
      color: 'inherit',
      _hover: {
        backgroundColor: 'white',
        color: 'primary.500',
        borderColor: 'primary.200',
      },
    },
    toolbar: {
      backgroundColor: 'gray.600',
      color: 'white',
      _hover: {
        backgroundColor: 'gray.700',
      },
    },
    editorGhost: {
      fontWeight: 200,
      color: 'gray.400',
      backgroundColor: 'gray.800',
      _hover: {
        backgroundColor: 'gray.700',
        color: 'white',
      },
    },
    ghostLink: {
      fontWeight: 200,
      color: 'white',
      _hover: {
        color: 'white',
      },
    },
    toolbarIcon: {
      backgroundColor: 'transparent',
      color: 'gray.400',
      _hover: {
        backgroundColor: 'gray.700',
      },
    },
    'panel-icon': {
      backgroundColor: 'transparent',
      color: 'gray.400',
      _hover: {
        backgroundColor: 'gray.700',
      },
      height: 7,
      minWidth: 7,
      fontSize: 'md',
    },
    layer: {
      borderRadius: '0',
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: 'sm',
      justifyContent: 'space-between',
      padding: INFO_PANEL_PADDING,
      _hover: {
        backgroundColor: 'whiteAlpha.50',
      },
    },
    darkGray: {
      backgroundColor: 'gray.600',
      color: 'white',
      _hover: {
        backgroundColor: 'gray.700',
        color: 'white',
      },
    },
    // ## Panel-specific styles
    panel: {
      color: '#fff',
      backgroundColor: 'gray.600',
      width: '100%',
    },
    'panel-dark': {
      bgColor: 'gray.800',
      _hover: {
        backgroundColor: 'gray.700',
        _disabled: {
          background: 'gray.700',
        },
      },
    },
    'panel-dropdown': {
      textAlign: 'left',
      backgroundColor: 'gray.700',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'rgba(255, 255, 255, 0.08)',
      borderRadius: 'md',
      width: '100%',
      paddingBlock: 0,
      paddingInline: 2,
      color: '#fff',
      fontSize: 'sm',
      height: 8,
    },
  },
}

const Divider = {
  variants: {
    layer: {
      border: 0,
      borderColor: 'whiteAlpha.200',
      borderBottomStyle: 'solid',
    },
  },
}

const FormLabel = {
  baseStyle: {
    color: 'secondary.500',
  },
}

const Link = {
  variants: {
    button: {
      fontWeight: 'semibold',
      paddingBottom: '4',
      paddingLeft: '8',
      paddingRight: '8',
      paddingTop: '4',
      _hover: {
        textDecoration: 'none',
        backgroundColor: 'blackAlpha.100',
      },
    },
    plain: {
      _hover: {
        textDecoration: 'none',
      },
    },
    underline: {
      textDecoration: 'underline',
      color: 'primary.500',
    },
    neutral: {
      color: 'primary.500',
    },
    toolbar: {
      backgroundColor: 'gray.800',
      color: 'white',
      _hover: {
        backgroundColor: 'gray.800',
      },
      _active: {
        backgroundColor: 'gray.800',
      },
      _focus: {
        backgroundColor: 'gray.800',
      },
    },
  },
}

const Menu = menuHelper.defineMultiStyleConfig({
  baseStyle: {
    item: {
      paddingBottom: '2.5',
      paddingTop: '2.5',
    },
    list: {
      minW: '40',
      boxShadow: 'md',
      border: '0',
    },
  },
  variants: {
    toolbar: {
      item: {
        backgroundColor: 'gray.700',
        color: 'white',
        _hover: {
          backgroundColor: 'gray.600',
        },
        _active: {
          backgroundColor: 'gray.600',
        },
        _focus: {
          backgroundColor: 'gray.600',
        },
      },
      list: {
        backgroundColor: 'gray.700',
      },
    },
    // ## Panel-specific styles
    panel: {
      button: Button.variants['panel-dropdown'],
      list: {
        backgroundColor: Button.variants['panel-dropdown'].backgroundColor,
      },
      item: {
        backgroundColor: Button.variants['panel-dropdown'].backgroundColor,
        color: 'white',
        _hover: {
          backgroundColor: Button.variants['panel-dropdown'].borderColor,
        },
      },
    },
  },
})

const Alert = alertHelper.defineMultiStyleConfig({
  variants: {
    // ## Panel-specific styles
    'panel-info': {
      container: {
        color: '#fff',
        backgroundColor: 'primary.900',
        borderRadius: 'md',
        opacity: 1,
        flexDirection: 'column',
        paddingBottom: 2,
        paddingTop: 2,
        paddingInlineStart: 3,
        paddingInlineEnd: 3,
      },
    },
    'panel-warning': {
      container: {
        color: '#fff',
        backgroundColor: 'orange.500',
        borderRadius: 'md',
        opacity: 1,
        flexDirection: 'column',
        paddingBottom: 2,
        paddingTop: 2,
        paddingInlineStart: 3,
        paddingInlineEnd: 3,
      },
    },
  },
})

// define styles for custom variant
const Tooltip = {
  variants: {
    light: {
      color: 'gray.700',
      '--tooltip-bg': 'var(--chakra-colors-gray-100)',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.3),0 2px 4px -1px rgba(0, 0, 0, 0.15)',
    },
  },
}

const Tabs = tabsHelper.defineMultiStyleConfig({
  variants: {
    panel: {
      tab: {
        backgroundColor: 'gray.900',
        opacity: 0.7,
        _selected: {
          backgroundColor: 'gray.800',
          opacity: 1,
        },
        _hover: {
          backgroundColor: 'gray.700',
          opacity: 1,
        },
      },
      tablist: {
        backgroundColor: 'gray.900',
      },
      tabpanel: {
        backgroundColor: 'gray.800',
        paddingTop: 2,
        paddingBottom: 1,
        paddingLeft: 4,
        paddingRight: 2,
      },
    },
  },
})

const theme = extendTheme(
  merge(PDLTheme, {
    components: {
      Alert,
      Button,
      Divider,
      FormLabel,
      Link,
      Menu,
      Tooltip,
      Tabs,
    },
    styles: {
      global: {
        body: {
          color: 'secondary.600',
          fontSize: 'sm',
        },
        a: {
          color: 'primary.600',
        },
      },
    },
  }),
)

export default theme
