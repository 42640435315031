import { FC, memo, useCallback, useContext } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import { isTablet } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ResetGridLockIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, GRID_MINIMUM_INTERVAL, PanelType } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { setLongAxisInterval, setShortAxisInterval, updateInterval } from '../../store'

const GridIntervalPanel: FC = () => {
  // Context
  const { selectedTool } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const selectedVolumeId = useSelector((state: RootState) => state.toolGrid.selectedVolumeId)
  const interval = intervals[selectedVolumeId] || null

  /**
   * Reset interval lock so it would be editable again.
   * Will remove any arbitrary placed grid points.
   */
  const resetIntervalLock = useCallback(() => {
    dispatch(updateInterval({ id: selectedVolumeId, interval: { ...interval, locked: false } }))
  }, [dispatch, interval, selectedVolumeId])

  if (selectedTool !== EDITOR_TOOLS.GRID && Object.keys(intervals).length === 0) {
    return null
  }

  return (
    <CollapsePanel title="グリッド間隔" type={PanelType.Tool} onChange={() => null} data-testid="grid-interval-panel">
      {!interval && (
        <Box textAlign="center" py={5}>
          <Text>グリッドを作成する領域の平面を選んでください</Text>
        </Box>
      )}

      {interval?.locked && (
        <Box textAlign="center" py={5}>
          <Text>手動で点を移動したグリッドの間隔は変更できません</Text>
          <Button
            colorScheme="secondary"
            fontSize="xs"
            variant="toolbar"
            size={isTablet ? 'lg' : 'sm'}
            mt={5}
            rightIcon={<ResetGridLockIcon size={18} />}
            onClick={resetIntervalLock}
          >
            リセット
          </Button>
        </Box>
      )}

      {interval && !interval.locked && (
        <>
          {/* Longer axis */}
          <FormControl
            px={INFO_PANEL_PADDING}
            py={INFO_PANEL_PADDING}
            isInvalid={
              interval.longAxis.value < GRID_MINIMUM_INTERVAL || interval.longAxis.value > interval.longAxis.max
            }
          >
            <Text>長辺</Text>
            <InputGroup size="sm">
              <Input
                type="number"
                value={interval.longAxis.value}
                size="sm"
                min={GRID_MINIMUM_INTERVAL}
                max={interval.longAxis.max}
                background="gray.700"
                borderColor="whiteAlpha.200"
                borderRadius={5}
                isDisabled={!selectedVolumeId}
                onChange={(e) => dispatch(setLongAxisInterval(parseInt(e.target.value, 10)))}
              />
              <InputRightElement fontSize="sm" px={5}>
                mm
              </InputRightElement>
            </InputGroup>
            <Box mt={2} mx={2}>
              <Slider
                value={interval.longAxis.value}
                min={GRID_MINIMUM_INTERVAL}
                max={interval.longAxis.max}
                step={1}
                isDisabled={!selectedVolumeId}
                onChange={(val) => dispatch(setLongAxisInterval(val))}
                focusThumbOnChange={false}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Box>
            <FormErrorMessage fontSize="xs">
              {GRID_MINIMUM_INTERVAL}~{interval.longAxis.max}の間の値を設定してください
            </FormErrorMessage>
          </FormControl>

          {/* Shorter axis */}
          <FormControl
            px={INFO_PANEL_PADDING}
            py={INFO_PANEL_PADDING}
            isInvalid={
              interval.shortAxis.value < GRID_MINIMUM_INTERVAL || interval.shortAxis.value > interval.shortAxis.max
            }
          >
            <Text>短辺</Text>
            <InputGroup size="sm">
              <Input
                type="number"
                value={interval.shortAxis.value}
                size="sm"
                min={GRID_MINIMUM_INTERVAL}
                max={interval.shortAxis.max}
                background="gray.700"
                borderColor="whiteAlpha.200"
                borderRadius={5}
                isDisabled={!selectedVolumeId}
                onChange={(e) => dispatch(setShortAxisInterval(parseInt(e.target.value, 10)))}
              />
              <InputRightElement fontSize="sm" px={5}>
                mm
              </InputRightElement>
            </InputGroup>
            <Box mt={2} mx={2}>
              <Slider
                value={interval.shortAxis.value}
                min={GRID_MINIMUM_INTERVAL}
                max={interval.shortAxis.max}
                step={1}
                isDisabled={!selectedVolumeId}
                onChange={(val) => dispatch(setShortAxisInterval(val))}
                focusThumbOnChange={false}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Box>
            <FormErrorMessage fontSize="xs">
              {GRID_MINIMUM_INTERVAL}~{interval.shortAxis.max}の間の値を設定してください
            </FormErrorMessage>
          </FormControl>
        </>
      )}
    </CollapsePanel>
  )
}

export default memo(GridIntervalPanel)
