import { useCallback } from 'react'

import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { useEditorContext } from 'contexts/Editor'

import { EditorPanelItem, ElementsPanelConfig } from 'interfaces/editor'

import { zeroPad } from 'services/Util'

import { removePlane, resetWorkingPoints } from '../store'

const useElementsPanel = (): ElementsPanelConfig => {
  // Context
  const { inspectionItems, shapes } = useEditorContext()

  // Store
  const dispatch = useAppDispatch()
  const planes = useSelector((state: RootState) => state.toolPlaneDetection.planes)
  const planeWorkingPoints = useSelector((root: RootState) => root.toolPlaneDetection.workingPoints)
  const isLoading = useSelector((state: RootState) => state.toolPlaneDetection.isLoading)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  return {
    getPanelItems: useCallback(
      (parent?: EditorPanelItem['item']) => {
        // Surface plane is parent-level, ignore if parent is provided
        if (parent) return []

        const planeItems = inspectionItems.filter((item) => item.item_type === 'polygon_area' && !item.volume_id)

        if (planeItems.length || planes.length || planeWorkingPoints.length) {
          return [
            {
              key: 'panel-surface-plane',
              label: '面積',
              isVirtualContainer: true,
              isCountable: true,
              children: [
                ...planeItems.map((item, index) => ({
                  key: `panel-surface-plane-${item.inspection_item_id}`,
                  label: item?.part_name || `面積${zeroPad(index + 1, 3)}`,
                  item: shapes.polygons.find((shape) => item.shape_ids.polygons.includes(shape.shape_id)),
                  onDelete: isAllowedToModify,
                })),
                ...planes.map((label, index) => ({
                  key: `panel-surface-plane-${label.id}`,
                  label: `面積${zeroPad(index + planeItems.length + 1, 3)}`,
                  item: label,
                  isWorking: true,
                  isSaving: isLoading,
                  onDelete: isLoading ? false : () => dispatch(removePlane(index)),
                })),
                planeWorkingPoints.length > (isMobile ? 0 : 1)
                  ? {
                      key: 'panel-surface-plane-working',
                      label: `面積${zeroPad(planes.length + planeItems.length + 1, 3)}`,
                      toggleVisibility: null,
                      isWorking: true,
                      isSaving: isLoading,
                      onDelete: isLoading ? false : () => dispatch(resetWorkingPoints()),
                    }
                  : null,
              ].filter(Boolean) as EditorPanelItem[],
            },
          ]
        }

        return []
      },
      [inspectionItems, shapes, planes, planeWorkingPoints.length, isLoading, isAllowedToModify, dispatch],
    ),
  }
}

export default useElementsPanel
