import { FC, useCallback, useContext, useMemo } from 'react'

import { Text } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { setElementDeleting, setSelectedElementIds } from 'pages/projects/editor/store/editor'
import { removeMaskRegion, setMaskRegions, updateMaskRegion } from 'pages/projects/editor/tools/MaskPCD/store'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { useGlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { Cuboid } from 'interfaces/shape'

import { deleteMaskingRegion } from 'services/MaskingRegion'
import { withPromiseContained } from 'services/Util'

import { reset } from '../../shapes/cuboid/store'
import { Layer } from '../components/Layer'

const MaskingRegions: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const maskRegions = useSelector((state: RootState) => state.maskPCD.regions)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)
  const cuboidAnchor = useSelector((state: RootState) => state.cuboid.anchor)
  const isLoading = useSelector((state: RootState) => state.maskPCD.isLoading)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useGlobalModalContext()

  // Vars
  const isAllRegionInvisible = useMemo(() => maskRegions.every((region) => region.invisible), [maskRegions])

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  /**
   * Handle deletion
   */
  const handleDelete = useCallback(
    (region: Cuboid) => {
      if (!project || !inspectionArea) {
        return
      }

      showModal({
        title: '注目領域',
        body: <Text>一度削除してしまうと、元に戻せません。</Text>,
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirm: () => {
          void withPromiseContained(async () => {
            dispatch(setSelectedElementIds([region.region_id]))
            dispatch(setElementDeleting(true))

            const token = await getAccessToken()
            if (!token) return

            dispatch(setAttentionText({ message: 'データを更新中...' }))

            const result = await deleteMaskingRegion(
              token,
              project.project_id,
              inspectionArea.inspection_area_id,
              region.region_id,
              showErrorModal,
            )
            if (!result) return

            mixpanel.track('Deleted mask region', {
              'Number of mask region': maskRegions.length - 1,
            })

            dispatch(removeMaskRegion(region.region_id))
          }).finally(() => {
            dispatch(setAttentionText({ message: '' }))
            dispatch(setElementDeleting(false))
            dispatch(setSelectedElementIds([]))
          })
          return true
        },
      })
    },
    [project, inspectionArea, maskRegions.length, getAccessToken, dispatch, showModal, showErrorModal],
  )

  /**
   * Update visibility of all mode value's visibility
   * @param {DepthType} depthType The object should contain the new value of visibility
   */
  const updateAllLayerVisibility = useCallback(
    (visibility: boolean) => {
      dispatch(setMaskRegions(maskRegions.map((region) => ({ ...region, invisible: visibility }))))
    },
    [maskRegions, dispatch],
  )

  /**
   * Update specfic mode value's visibility
   * @param {boolean} visibility New visibility
   * @param {number} index Array index of the mode
   */
  const updateLayerVisibility = useCallback(
    (visibility: boolean, cuboid: Cuboid) => {
      dispatch(updateMaskRegion({ ...cuboid, invisible: visibility }))
    },
    [dispatch],
  )

  if (maskRegions.length === 0 && !cuboidAnchor) {
    return null
  }

  return (
    <>
      {/* Toggle all regions */}
      {maskRegions.length ? (
        <Layer
          item={{
            key: 'panel-mask-region-all',
            label: 'すべての点を表示',
            isVirtualContainer: true,
            onSelect: false,
            toggleVisibility: {
              invisible: !isAllRegionInvisible,
              onToggle: () => updateAllLayerVisibility(!isAllRegionInvisible),
            },
          }}
          depth={0}
          collapsible={false}
          toolsPanelItems={[]}
        />
      ) : null}

      {/* Saved regions */}
      {maskRegions.map((region, index) => (
        <Layer
          item={{
            key: `panel-mask-region-${region.region_id}`,
            label: `注目領域 ${index + 1}`,
            item: region,
            onSelect: false,
            toggleVisibility: {
              invisible: region.invisible || false,
              onToggle: () => updateLayerVisibility(!region.invisible!, region),
            },
            onDelete: isAllowedToModify ? () => handleDelete(region) : false,
          }}
          depth={1}
          collapsible={false}
          toolsPanelItems={[]}
        />
      ))}

      {/* Working region */}
      {cuboidAnchor ? (
        <Layer
          item={{
            key: `panel-mask-region-working`,
            label: `注目領域 ${maskRegions.length + 1}`,
            isWorking: true,
            isSaving: isLoading,
            onSelect: false,
            toggleVisibility: null,
            onDelete: () => dispatch(reset()),
          }}
          depth={1}
          collapsible={false}
          toolsPanelItems={[]}
        />
      ) : null}
    </>
  )
}

export default MaskingRegions
