import { useCallback, useMemo, useState } from 'react'

import { Text, UnorderedList, VStack } from '@chakra-ui/react'
import { difference, uniq } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { INITIAL_SHAPE_STATE, useEditorContext } from 'contexts/Editor'
import { useGlobalModalContext } from 'contexts/GlobalModal'
import { useUserContext } from 'contexts/Users'

import { EDITOR_SHAPE_KEYS, MODAL_TYPES } from 'config/constants'

import { EditorPanelItem } from 'interfaces/editor'
import { ShapesId } from 'interfaces/shape'

import { deleteShapes } from 'services/InspectionArea'
import { batchDeleteInspectionItem } from 'services/InspectionSheet'
import { withPromiseContained } from 'services/Util'
import { getVolumeEstimationItems, pollAsyncJobStatus } from 'services/VolumeEstimation'

import {
  setDeletingElementIds,
  setElementDeleting,
  setHoveredElementId,
  toggleHiddenElementIds,
} from '../../store/editor'
import LayerSimple from '../components/LayerSimple'
import { collectItemsByIds, getCurrentAndDescendantIds, getId } from '../utils'

let ignoreHover = false

const useElementsPanel = (current?: EditorPanelItem) => {
  const { defaultCollapsed = false, toggleVisibility, onDelete, onSelect = true, onHover, item } = current || {}

  // Context
  const { showModal, showErrorModal } = useGlobalModalContext()
  const { getAccessToken } = useUserContext()
  const {
    inspectionItems,
    inspectionSheet,
    shapes,
    fetchInspectionItems,
    fetchShapes,
    changeIsJobRunning,
    changeSelectedShapeIds,
  } = useEditorContext()

  // Store
  const dispatch = useAppDispatch()
  const hiddenElementIds = useSelector((state: RootState) => state.editor.hiddenElementIds)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)

  // States
  const [collapsed, setCollapsed] = useState(defaultCollapsed)

  // Vars
  const isInvisible = useMemo(() => {
    if (current) {
      if (toggleVisibility && typeof toggleVisibility === 'object' && 'invisible' in toggleVisibility) {
        return toggleVisibility.invisible
      }

      return !difference(getCurrentAndDescendantIds(current), hiddenElementIds).length
    }

    return false
  }, [hiddenElementIds, current, toggleVisibility])
  const isSelected = useMemo(
    () =>
      current && onSelect !== false
        ? !difference(getCurrentAndDescendantIds(current), selectedElementIds).length
        : false,
    [selectedElementIds, current, onSelect],
  )

  /**
   * Handle visibility toggle.
   * Not to be used for batch operations.
   */
  const handleToggleVisibility = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()

      // If a custom function is provided, call it
      if (toggleVisibility && typeof toggleVisibility === 'object' && 'onToggle' in toggleVisibility) {
        toggleVisibility.onToggle()
        return
      }

      if (current) {
        const ids = getCurrentAndDescendantIds(current)
        if (!isInvisible) {
          ignoreHover = true
        }

        dispatch(setHoveredElementId(''))
        dispatch(toggleHiddenElementIds({ ids, invisible: !isInvisible }))
      }
    },
    [current, isInvisible, toggleVisibility, dispatch],
  )

  const runDeleteShapes = useCallback(
    async (token: string, shapesId: ShapesId) => {
      if (!project || !inspectionArea) return false

      // If the planes we're deleting has been used for volume estimation, make sure to delete both planes.
      const volumeEstimationItems = getVolumeEstimationItems(inspectionItems)
      if (volumeEstimationItems) {
        // Get all volume estimation items that are using the planes we're deleting. Get only plane IDs.
        const polyRemovedVolumeShapeIds = volumeEstimationItems
          .filter((it) => shapesId.polygons.find((id) => it.shape_ids.polygons?.includes(id)))
          .map((it) => it.shape_ids.polygons)
          .flat()

        // Add the planes we're deleting to the list of shapes to be deleted. Make sure to not add duplicates.
        if (polyRemovedVolumeShapeIds) {
          const polyMissingIds = polyRemovedVolumeShapeIds.filter((val) => !shapesId.polygons.includes(val))
          if (polyMissingIds.length) {
            shapesId[EDITOR_SHAPE_KEYS.POLYGONS] = shapesId[EDITOR_SHAPE_KEYS.POLYGONS].concat(polyMissingIds)
          }
        }
      }

      mixpanel.track('Deleting shapes', {
        'Shape amount': shapesId.cylinders.length + shapesId.polygons.length,
        'Shape IDs': shapesId,
      })

      const deleteResult = await deleteShapes(
        token,
        project.project_id,
        inspectionArea.inspection_area_id,
        shapesId,
        showErrorModal,
      )
      if (!deleteResult) return false

      // If the delete operation returns an array, it means it has some pending async job we need to further wait for.
      if (Array.isArray(deleteResult)) {
        await Promise.all(deleteResult.map(async (res) => pollAsyncJobStatus(token, res.job_token, showErrorModal)))
      }

      return deleteResult
    },
    [project, inspectionArea, inspectionItems, showErrorModal],
  )

  /**
   * Run delete inspection items to API.
   */
  const runDeleteInspectionItems = useCallback(
    async (token: string, inspectionItemIds: string[]) => {
      if (!inspectionItemIds.length || !project || !inspectionArea || !inspectionSheet) return false

      return batchDeleteInspectionItem(
        token,
        project.project_id,
        inspectionArea.inspection_area_id,
        inspectionSheet.inspection_sheet_id,
        inspectionItemIds,
        showErrorModal,
      )
    },
    [project, inspectionArea, inspectionSheet, showErrorModal],
  )

  /**
   * Handle deletion of saved items.
   *
   * @param ids The IDs to be deleted
   * @param toolPanelItems Generated tool panel items
   */
  const handleDeleteSavedItems = useCallback(
    (ids: string[], toolPanelItems: EditorPanelItem[]) => {
      if (!ids.length || !project || !inspectionArea) return

      // Compile shape IDs to be deleted
      const shapesId: ShapesId = INITIAL_SHAPE_STATE()
      ids.forEach((id) => {
        if (shapes.cylinders.some((shape) => shape.shape_id === id)) {
          shapesId.cylinders.push(id)
        }
      })
      ids.forEach((id) => {
        if (shapes.polygons.some((shape) => shape.shape_id === id)) {
          shapesId.polygons.push(id)
        }
      })
      const selectedShapesCount = shapesId.cylinders.length + shapesId.polygons.length

      // Compile inspection item IDs to be deleted
      const inspectionItemIds = ids.filter((id) => inspectionItems.some((it) => it.inspection_item_id === id))

      if (!selectedShapesCount && !inspectionItemIds.length) return

      const toBeDeleted = collectItemsByIds(ids, toolPanelItems)

      showModal({
        title: `選択した要素を削除してもよろしいですか？`,
        size: 'xl',
        body: (
          <VStack alignItems="start" maxH="600px" overflow="auto">
            <Text>一度削除してしまうと、元に戻せません。</Text>
            <Text>また、関連する測定値が全て削除されます。</Text>

            {/* List the shapes that will be deleted */}
            <UnorderedList w="90%" alignSelf="center">
              {toBeDeleted.map((it) => (
                <LayerSimple key={it.key} item={it} />
              ))}
            </UnorderedList>
          </VStack>
        ),
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirm: () => {
          void withPromiseContained(async () => {
            changeIsJobRunning(true)
            dispatch(setDeletingElementIds(ids))
            dispatch(setElementDeleting(true))
            dispatch(setAttentionText({ message: 'データを更新中...' }))

            const token = await getAccessToken()
            if (!token) return

            await Promise.all([
              selectedShapesCount ? runDeleteShapes(token, shapesId) : true,
              inspectionItemIds.length ? runDeleteInspectionItems(token, inspectionItemIds) : true,
            ])

            await Promise.all([fetchInspectionItems(), fetchShapes()])

            dispatch(setDeletingElementIds([]))
          }).finally(() => {
            changeIsJobRunning(false)
            dispatch(setAttentionText({ message: '' }))
            dispatch(setElementDeleting(false))
          })
          return true
        },
      })
    },
    [
      project,
      inspectionItems,
      inspectionArea,
      shapes,
      showModal,
      fetchInspectionItems,
      fetchShapes,
      changeIsJobRunning,
      runDeleteShapes,
      runDeleteInspectionItems,
      getAccessToken,
      dispatch,
    ],
  )

  /**
   * Handle delete.
   * Can be used for batch operations.
   *
   * @param ids The IDs to be deleted
   * @param toolPanelItems Generated tool panel items
   */
  const handleDelete = useCallback(
    async (ids: string[], toolPanelItems: EditorPanelItem[]) => {
      if (typeof onDelete === 'function') {
        await onDelete()
        return
      }

      handleDeleteSavedItems(ids, toolPanelItems)
    },
    [handleDeleteSavedItems, onDelete],
  )

  /**
   * Handle layer selection
   */
  const handleSelect = useCallback(() => {
    if (!current) return

    const ids = getCurrentAndDescendantIds(current)
    if (isSelected) {
      changeSelectedShapeIds(difference(selectedElementIds, ids))
    } else {
      changeSelectedShapeIds(uniq([...selectedElementIds, ...ids]))
    }
  }, [current, isSelected, selectedElementIds, changeSelectedShapeIds])

  /**
   * Handle hover event.
   * @param flag Mouse hover in or out
   */
  const handleHover = useCallback(
    (flag: boolean) => {
      if (onHover) {
        onHover(flag)
        return
      }

      if (ignoreHover) {
        ignoreHover = false
        return
      }

      if (!onSelect || !current || !item) return

      dispatch(setHoveredElementId(flag ? getId(current) : ''))
    },
    [onSelect, current, item, onHover, dispatch],
  )

  return {
    collapsed,
    isInvisible,
    isSelected,
    handleToggleVisibility,
    handleDelete,
    handleSelect,
    handleHover,
    setCollapsed,
  }
}

export default useElementsPanel
