import { EditorPanelItem } from 'interfaces/editor'

/**
 * Get the id of the item
 * @param it The item
 * @returns
 */
export const getId = (it: EditorPanelItem): string => {
  if (it.item && 'id' in it.item) return it.item.id
  if (it.item && 'inspection_item_id' in it.item) return it.item.inspection_item_id!
  if (it.item && 'shape_id' in it.item) return it.item.shape_id
  if (it.item && 'region_id' in it.item) return it.item.region_id
  return it.key
}

/**
 * Collect IDs for deletion
 *
 * @param it The item to be deleted
 * @returns
 */
export const getIdsForDeletion = (it: EditorPanelItem): string[] => {
  let ids: string[] = []

  // If this is a virtual container setup to be deleted,
  // run the delete with it's children instead.
  if (it.isVirtualContainer && it.onDelete === true && it.children) {
    ids = ids.concat(...it.children.map(getIdsForDeletion).flat())
  }
  // Custom provided list of IDs to be deleted
  else if (Array.isArray(it.onDelete)) {
    ids = ids.concat(it.onDelete)
  }
  // Delete itself
  else {
    ids.push(getId(it))
  }

  return ids
}

/**
 * Get the current and its descendant IDs
 * @param item The item
 * @returns
 */
export const getCurrentAndDescendantIds = (item: EditorPanelItem): string[] => {
  const ids = []

  if (!item.isVirtualContainer) {
    ids.push(getId(item))
  }

  if (!item.children) return ids

  return ids.concat(item.children.map(getCurrentAndDescendantIds).flat())
}

/**
 * Get the items by the provided IDs.
 *
 * The difference between this function and the `collectItemsByIds` function is that this function
 * will return items in a flat array while the `collectItemsByIds` function will maintain the hierarchy.
 *
 * @param ids
 * @param items
 */
export const getItemsByIds = (ids: string[], items: EditorPanelItem[]): EditorPanelItem[] => {
  const foundItems: EditorPanelItem[] = []
  items.forEach((it) => {
    if (ids.includes(getId(it))) {
      foundItems.push(it)
    }

    if (it.children) {
      foundItems.push(...getItemsByIds(ids, it.children))
    }
  })

  return foundItems
}

/**
 * Collect the item by the provided IDs.
 * - If the item is a parent, it will check its children.
 * - If the item is a child, it will check if it itself is within the ID collections.
 * - If the item itself is found, it will be returned.
 * - Otherwise, it will return null.
 *
 * @param item The item found or null if the provided item is not selected
 */
export const collectItemByIds = (selectedIds: string[], it: EditorPanelItem): EditorPanelItem | null => {
  if (it.children?.length) {
    const updated = { ...it }
    updated.children = it.children
      .map((child) => {
        if (child.children) {
          return collectItemByIds(selectedIds, child)
        }

        return false
      })
      .filter(Boolean) as EditorPanelItem[]

    if (updated.children.length) {
      return updated
    }
  }

  if (selectedIds.includes(getId(it))) {
    return it
  }

  return null
}

/**
 * Collect items according to the provided IDs.
 *
 * The difference between this function and the `getItemsByIds` function is that this function
 * will maintain the hierarchy while the `getItemsByIds` function will return items in a flat array.
 *
 * @param ids IDs to be collected
 * @param its Items to be checked
 */
export const collectItemsByIds = (ids: string[], its: EditorPanelItem[]): EditorPanelItem[] => {
  const items: EditorPanelItem[] = []
  its.forEach((it) => {
    if (!it) return

    const item = collectItemByIds(ids, it)
    if (item) {
      items.push(item)
    }
  })

  return items
}
