import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { DistanceLabelProps, InspectionItem } from 'interfaces/interfaces'

export interface CustomMeasurement extends Partial<InspectionItem> {
  distanceLabel: DistanceLabelProps
}

export interface CustomMeasurementState {
  /**
   * Distance measurements.
   */
  measurements: CustomMeasurement[]

  /**
   * Temporary distance measurements for users without permission to save.
   */
  tempMeasurements: CustomMeasurement[]
}

const initialState: CustomMeasurementState = {
  measurements: [],
  tempMeasurements: [],
}

export const slice = createSlice({
  name: 'editor/tools/custom_measurement',
  initialState,
  reducers: {
    /**
     * Saved measurements.
     */
    setCustomMeasurements: (state, action: PayloadAction<CustomMeasurement[]>) => {
      state.measurements = action.payload
    },
    updateCustomMeasurement: (state, action: PayloadAction<CustomMeasurement>) => {
      state.measurements = state.measurements.map((measurement) =>
        measurement.inspection_item_id === action.payload.inspection_item_id ? action.payload : measurement,
      )
    },
    /**
     * Temporary measurements, for invited users without permission to save.
     */
    setTempMeasurements: (state, action: PayloadAction<CustomMeasurement[]>) => {
      state.tempMeasurements = action.payload
    },
    updateTempMeasurement: (state, action: PayloadAction<CustomMeasurement>) => {
      state.tempMeasurements = state.tempMeasurements.map((measurement) =>
        measurement.inspection_item_id === action.payload.inspection_item_id ? action.payload : measurement,
      )
    },
    deleteTempMeasurement: (state, action: PayloadAction<string>) => {
      state.tempMeasurements = state.tempMeasurements.filter(
        (measurement) => measurement.inspection_item_id !== action.payload,
      )
    },
  },
})

export const {
  setCustomMeasurements,
  updateCustomMeasurement,

  setTempMeasurements,
  updateTempMeasurement,
  deleteTempMeasurement,
} = slice.actions

export default slice.reducer
