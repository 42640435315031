import { useCallback } from 'react'

import { Circle, HStack, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { useEditorContext } from 'contexts/Editor'

import { EditorPanelItem, ElementsPanelConfig } from 'interfaces/editor'
import { InspectionItem } from 'interfaces/inspection'
import { PlaneSide } from 'interfaces/shape'

import { getDepthTypeColor } from 'services/DepthEstimation'

const useElementsPanel = (): ElementsPanelConfig => {
  // Context
  const { inspectionItems } = useEditorContext()

  // Store
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  return {
    /**
     * Return panel items as child of volume with 2 fixed items;
     * 1 for upper plane to cylinder, and 1 for lower plane to cylinder.
     */
    getPanelItems: useCallback(
      (parent?: EditorPanelItem['item']): EditorPanelItem[] => {
        // Depth falls under a volume
        if (!parent || !('item_type' in parent) || parent.item_type !== 'volume') return []
        const prnt = parent as InspectionItem

        const items: EditorPanelItem[] = []
        const upper = inspectionItems.find(
          (item) =>
            item.volume_id === prnt.inspection_item_id &&
            item.item_type === 'plane_to_cylinders_distance' &&
            item.plane_side === PlaneSide.UPPER,
        )
        if (upper) {
          items.push({
            key: `panel-depth-upper-${upper.inspection_item_id}`,
            label: (
              <HStack display="inline-flex">
                <Text>表かぶり厚</Text>
                <Circle size="1em" bg={getDepthTypeColor(1)} />
              </HStack>
            ),
            item: upper,
            onDelete: false,
          })
        }

        const lower = inspectionItems.find(
          (item) =>
            item.volume_id === prnt.inspection_item_id &&
            item.item_type === 'plane_to_cylinders_distance' &&
            item.plane_side === PlaneSide.LOWER,
        )
        if (lower) {
          items.push({
            key: `panel-depth-lower-${lower.inspection_item_id}`,
            label: (
              <HStack display="inline-flex">
                <Text>裏かぶり厚</Text>
                <Circle size="1em" bg={getDepthTypeColor(2)} />
              </HStack>
            ),
            item: lower,
            onDelete: false,
          })
        }

        if (items.length) {
          return [
            {
              key: `panel-depth-${prnt.inspection_item_id}`,
              label: '表示',
              children: items,
              isVirtualContainer: true,
              onDelete: isAllowedToModify,
            },
          ]
        }

        return []
      },
      [inspectionItems, isAllowedToModify],
    ),
  }
}

export default useElementsPanel
