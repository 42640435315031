import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorPanelItem, ElementsPanelConfig } from 'interfaces/editor'

import { zeroPad } from 'services/Util'

import { deleteWorkingPolylines } from '../store'

const useElementsPanel = (): ElementsPanelConfig => {
  // Hook
  const polylines = useSelector((state: RootState) => state.toolPolyline.polylines)
  const workingPolylines = useSelector((state: RootState) => state.toolPolyline.workingPolylines)

  // Store
  const dispatch = useAppDispatch()
  const isLoading = useSelector((root: RootState) => root.toolPolyline.isLoading)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Permission set
  const isAllowedToDetect = permissionSet.DETECT.includes(userType)

  return {
    getPanelItems: useCallback(
      (parent?: EditorPanelItem['item']): EditorPanelItem[] => {
        // Polyline is parent-level, ignore if parent is provided
        if (parent) return []

        if (polylines.length || workingPolylines.length) {
          return [
            {
              key: 'panel-polyline',
              label: '延長',
              isVirtualContainer: true,
              isCountable: true,
              children: [
                ...polylines.map((item, index) => ({
                  key: `panel-polyline-${item.inspection_item_id}`,
                  label: item?.part_name || `延長${zeroPad(index + 1, 3)}`,
                  item,
                  onDelete: isAllowedToDetect,
                })),
                ...workingPolylines.map((item, index) => ({
                  key: `panel-polyline-working-${item.inspection_item_id}`,
                  label: item?.part_name || `延長${zeroPad(index + polylines.length + 1, 3)}`,
                  item,
                  isWorking: true,
                  isSaving: isLoading,
                  onDelete: isLoading ? false : () => dispatch(deleteWorkingPolylines([item.inspection_item_id!])),
                })),
              ],
            },
          ]
        }

        return []
      },
      [polylines, workingPolylines, isLoading, isAllowedToDetect, dispatch],
    ),
  }
}

export default useElementsPanel
