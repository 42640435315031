import React, { useCallback, useContext, useMemo, useState } from 'react'

import { CursorState, setCursor, setIsDragging } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Plane, Quaternion, Raycaster, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'
import { PLANE_SIDE_COLOR } from 'config/styles'

import { PointArray } from 'interfaces/attribute'
import { CircleAnchorIcon, CircleAnchorProps } from 'interfaces/canvas'
import { PlaneSide } from 'interfaces/shape'

import { getClickCoords, rotatePointAroundPivot } from 'services/Points'

import {
  DrawingStage,
  PANEL_WORKING_PLANE_LOWER_ID,
  PANEL_WORKING_PLANE_UPPER_ID,
  setClosing,
  updateBottomPlaneAnchor,
  updateTopPlaneAnchor,
  updateTopPlaneAnchorAdjustedPoints,
} from '../store'

const raycaster = new Raycaster()

/**
 * Setting up anchors for display and its event handlers.
 */
export const useAnchors = (
  threePlanes: Record<PlaneSide | 'working', Plane>,
  lastTouchClient: { x: number; y: number } | undefined,
) => {
  // Context
  const { selectedTool, cameraRef } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const hiddenShapeIds = useSelector((state: RootState) => state.editor.hiddenElementIds)
  const isLoading = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.isSaving)
  const isClosing = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.isClosing)
  const workingPoints = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.workingPoints)
  const drawingStage = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.drawingStage)
  const heightSelectionPoints = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.heightSelectionPoint)
  const completedPlanes = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.planes)
  const planeCreationOrder = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.planeCreationOrder)
  const currentTransformQuaternion = useSelector(
    (root: RootState) => root.toolVolumeEstimationPolygon.currentTransformQuaternion,
  )

  // States
  const [movingAnchor, setMovingAnchor] = useState<{ index: number; planeType: PlaneSide | 'working' } | undefined>(
    undefined,
  )

  // Vars
  const isTopVirtual = useMemo(() => planeCreationOrder[1] === PlaneSide.UPPER, [planeCreationOrder])
  const isSelectedTool = useMemo(() => selectedTool === EDITOR_TOOLS.VOLUME_POLYGON, [selectedTool])

  /**
   * Get tooltip to be used for the first anchor.
   */
  const getFirstAnchorTooltip = useCallback(() => {
    if (drawingStage !== DrawingStage.Draw || workingPoints.length < 3) return undefined

    return workingPoints.length > 3
      ? 'この平面の作成を完了'
      : `この平面の作成を完了するには、あと${4 - workingPoints.length}点以上が必要です`
  }, [drawingStage, workingPoints])

  /**
   * Event handler for selecting an anchor for adjustment.
   *
   * @param index Index of the anchor within it's respective plane.
   * @param planeType Type of the plane.
   */
  const onSelectAnchor = useCallback(
    (index: number, planeType: PlaneSide | 'working') => {
      setMovingAnchor({ index, planeType })
      dispatch(setIsDragging(true)) // This is toggled off in MainCanvas::onMouseUp
      dispatch(setCursor(CursorState.GRABBING))
    },
    [dispatch],
  )

  /**
   * Event handler for moving an anchor.
   *
   * @param position New position of the anchor.
   */
  const onMoveAnchor = useCallback(
    (
      point?: PointArray,
      mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
      touchEvent?: React.TouchEvent<HTMLDivElement>,
    ) => {
      if (movingAnchor) {
        const { index, planeType } = movingAnchor

        // Get the other plane as reference point
        const plane = threePlanes[planeType]
        if (!plane) return

        let newPoint: Vector3 | undefined

        // If we're moving an anchor for the upper plane, and  the upper plane is a virtual plane
        // user can move the anchor anywhere along the plane regardless of PCD.
        if (planeType === PlaneSide.UPPER && isTopVirtual && cameraRef.current) {
          const targetElem = document.getElementById('canvasBox')?.getBoundingClientRect()
          if (!targetElem) {
            return
          }

          const { x, y, width, height } = targetElem
          const { coords } = getClickCoords(
            {
              x: mouseEvent?.clientX || touchEvent?.touches[0].clientX || 0,
              y: mouseEvent?.clientY || touchEvent?.touches[0].clientY || 0,
            },
            {
              x,
              y,
              width,
              height,
            },
          )
          newPoint = new Vector3()

          raycaster.setFromCamera(coords, cameraRef.current)
          raycaster.ray.intersectPlane(plane, newPoint)
        }
        // Otherwise, restrict it to the PCD
        else if (point) {
          newPoint = plane.projectPoint(new Vector3(...point), new Vector3())
        }

        if (!newPoint) return

        if (planeType === PlaneSide.UPPER) {
          dispatch(
            updateTopPlaneAnchor({
              index,
              position: newPoint.toArray(),
            }),
          )

          // anchorAdjustedPoints needs to be calculated separately since it is suppose to be the
          // 'reference' points for the transform controls so we need to calculate it back to
          // the reference position, alongside the other points.
          if (currentTransformQuaternion && heightSelectionPoints) {
            dispatch(
              updateTopPlaneAnchorAdjustedPoints({
                index,
                position: rotatePointAroundPivot(
                  new Vector3(...newPoint),
                  new Vector3(...heightSelectionPoints),
                  new Quaternion().fromArray(currentTransformQuaternion).invert(),
                ).toArray(),
              }),
            )
          } else {
            dispatch(
              updateTopPlaneAnchorAdjustedPoints({
                index,
                position: newPoint.toArray(),
              }),
            )
          }
          return
        }

        if (planeType === PlaneSide.LOWER) {
          dispatch(
            updateBottomPlaneAnchor({
              index,
              position: newPoint.toArray(),
            }),
          )
        }

        // If planeType is undefined, it means the anchor is on the working points
      }
    },
    [movingAnchor, threePlanes, cameraRef, isTopVirtual, currentTransformQuaternion, heightSelectionPoints, dispatch],
  )

  /**
   * Event handler for ending anchor movement.
   */
  const onEndMoveAnchor = useCallback(() => {
    setMovingAnchor(undefined)
  }, [])

  /**
   * Setup working point anchors
   */
  const workingPointAnchors = useMemo(() => {
    const anchors: CircleAnchorProps[] = []

    // During drawing, first anchor is always grey, unless there's only one, then it's red
    if (workingPoints.length && drawingStage === DrawingStage.Draw) {
      let style: Partial<CircleAnchorProps> = {
        scale: 1,
        color: workingPoints.length === 1 && !lastTouchClient ? 'red' : '#777',
      }
      if ((!lastTouchClient && workingPoints.length > 3) || (lastTouchClient && workingPoints.length > 2)) {
        style = {
          color: '#248CE4',
          icon: CircleAnchorIcon.CHECK,
          iconColor: 'white',
          outlineOpacity: 0,
          scale: 1.3,
          iconScale: 1.1,
        }
      }

      anchors.push({
        id: 'polygon-working-points-first',
        point: workingPoints[0],
        ...style,
        tooltipText: getFirstAnchorTooltip(),
        onEnter: () => {
          if (workingPoints.length > 3) {
            dispatch(setCursor(CursorState.POINTER))
            dispatch(setClosing(true))
          }
        },
        onLeave: () => {
          if (workingPoints.length > 3) {
            dispatch(setClosing(false))
            dispatch(setCursor(CursorState.DEFAULT))
          }
        },
      })
    }

    // During initial drawing, if there are more than 1 points, set the last anchor as red
    if (workingPoints.length > 1 && !isClosing && drawingStage === DrawingStage.Draw) {
      anchors.push({
        id: 'polygon-working-points-last',
        point: workingPoints.slice(-1)[0],
        color: 'red',
        scale: 1,
      })
    }

    // height selection point is orange
    if (
      heightSelectionPoints &&
      (drawingStage === DrawingStage.Height || (drawingStage === DrawingStage.Complete && isTopVirtual))
    ) {
      anchors.push({
        id: 'polygon-working-height-selection-point',
        point: heightSelectionPoints,
        color: 'orange',
        scale: 1.1,
      })
    }

    return anchors
  }, [
    heightSelectionPoints,
    drawingStage,
    isClosing,
    workingPoints,
    isTopVirtual,
    lastTouchClient,
    dispatch,
    getFirstAnchorTooltip,
  ])

  /**
   * Setup plane anchors, only for complete stage.
   */
  const planeAnchors = useMemo(() => {
    if (drawingStage !== DrawingStage.Complete || !completedPlanes.upper || !completedPlanes.lower || isLoading)
      return []

    const anchors: CircleAnchorProps[] = [
      ...(!hiddenShapeIds.includes(PANEL_WORKING_PLANE_UPPER_ID)
        ? completedPlanes.upper.points.map(
            (point, index) =>
              ({
                id: `polygon-upper-plane-anchor-${index}`,
                point,
                color:
                  movingAnchor?.planeType === PlaneSide.UPPER && movingAnchor?.index === index
                    ? 'red'
                    : PLANE_SIDE_COLOR[PlaneSide.UPPER],
                icon: CircleAnchorIcon.MOVE,
                iconColor: 'white',
                outlineOpacity: 0,
                scale: 1.3,
                iconScale: 1.1,
                tooltipText: isSelectedTool ? '点を移動' : undefined,
                onDown: isSelectedTool ? () => onSelectAnchor(index, PlaneSide.UPPER) : undefined,
                onEnter: isSelectedTool
                  ? () => {
                      dispatch(setCursor(CursorState.GRAB))
                    }
                  : undefined,
                onLeave: () => {
                  if (!movingAnchor) dispatch(setCursor(CursorState.DEFAULT))
                },
              }) as CircleAnchorProps,
          )
        : []),
      ...(!hiddenShapeIds.includes(PANEL_WORKING_PLANE_LOWER_ID)
        ? completedPlanes.lower.points.map(
            (point, index) =>
              ({
                id: `polygon-lower-plane-anchor-${index}`,
                point,
                color:
                  movingAnchor?.planeType === PlaneSide.LOWER && movingAnchor?.index === index
                    ? 'red'
                    : PLANE_SIDE_COLOR[PlaneSide.LOWER],
                icon: CircleAnchorIcon.MOVE,
                iconColor: 'white',
                outlineOpacity: 0,
                scale: 1.3,
                iconScale: 1.1,
                tooltipText: isSelectedTool ? '点を移動' : undefined,
                onDown: isSelectedTool ? () => onSelectAnchor(index, PlaneSide.LOWER) : undefined,
                onEnter: isSelectedTool
                  ? () => {
                      dispatch(setCursor(CursorState.GRAB))
                    }
                  : undefined,
                onLeave: () => {
                  if (!movingAnchor) dispatch(setCursor(CursorState.DEFAULT))
                },
              }) as CircleAnchorProps,
          )
        : []),
    ]

    return anchors
  }, [drawingStage, movingAnchor, isSelectedTool, completedPlanes, isLoading, hiddenShapeIds, onSelectAnchor, dispatch])

  return {
    anchors: [workingPointAnchors, planeAnchors].flat(),
    movingAnchor,
    onMoveAnchor,
    onEndMoveAnchor,
  }
}
