import { ToolbarMaskRegion } from 'assets/icons'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, USER_TYPES } from 'config/constants'

import { EditorTool, ToolbarCategory } from 'interfaces/editor'
import { PermissionSets } from 'interfaces/validation'

import useEditor from './hooks/useEditor'
import useMainCanvas from './hooks/useMainCanvas'

const MaskPCD: EditorTool = {
  key: EDITOR_TOOLS.PCD_TRIM_CUBOID,
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) =>
    permissionSets.MAIN_CANVAS.DETECT.includes(userType),
  toolbar: {
    icon: <ToolbarMaskRegion size="50%" />,
    label: EDITOR_TOOLS_LABELS.PCD_TRIM_CUBOID,
    category: ToolbarCategory.Manipulate,
  },
  hooks: {
    useEditor,
    useMainCanvas,
  },
  config: {
    additionalToolbars: {
      cuboidControls: true,
    },
  },
}

export default MaskPCD
