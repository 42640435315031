import { useCallback, useContext, useEffect, useMemo } from 'react'

import mixpanel from 'mixpanel-browser'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_REQUIRED_ANCHORS, EDITOR_TOOLS } from 'config/constants'

import { Editor } from 'interfaces/canvas'
import { EditorConfig } from 'interfaces/editor'

import { createMaskingRegion } from 'services/MaskingRegion'

import { reset, setCuboidAnchor, setEditingCuboid } from '../../../shapes/cuboid/store'
import { addMaskRegion, setIsLoading, setMouseAnchor } from '../store'

/**
 * Configure the editor for PCD Trim tool.
 */
export default function useEditor({ selectedTool, isPreviousTool, changeTool }: Editor): EditorConfig {
  // URL Params
  const { project_id } = useParams<{ project_id: string }>()
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Store
  const dispatch = useAppDispatch()
  const isLoading = useSelector((state: RootState) => state.maskPCD.isLoading)
  const cuboidAnchor = useSelector((state: RootState) => state.cuboid.anchor)
  const editingCuboid = useSelector((state: RootState) => state.cuboid.editingCuboid)
  const maskRegions = useSelector((state: RootState) => state.maskPCD.regions)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Vars
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID, [selectedTool])

  /**
   * Show attention text when tool is activated.
   */
  useEffect(() => {
    if (selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID) {
      dispatch(
        setAttentionText({
          message:
            '注目領域内の点のみを表示します。領域外の点は非表示になります（再表示できます）。\n' +
            '注目領域を作成するため、3点を選んで面を選択してください。その後、1点を選んで奥行きを設定してください。\n' +
            '調整完了後、右下の保存ボタンを押してください。',
          linkMessage: '詳細はこちら（操作動画）',
          linkUrl: 'https://datalabs-jp.notion.site/a176ae9a89b94aa1889f10c57742d862',
        }),
      )
    }
  }, [dispatch, selectedTool])

  /**
   * On change to different tool, reset the processing anchor.
   */
  useEffect(() => {
    if (!isToolSelected && isPreviousTool(EDITOR_TOOLS.PCD_TRIM_CUBOID) && selectedTool !== EDITOR_TOOLS.FOCUS) {
      dispatch(setMouseAnchor(undefined))
    }
  }, [isToolSelected, selectedTool, isPreviousTool, dispatch])

  /**
   * Reset on unmount.
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  return {
    buttons: {
      submit: {
        key: 'save-mask-region',
        label: '保存',
        loadingLabel: '保存中',
        onClick: useCallback(async () => {
          if (!editingCuboid || !project_id || !inspection_area_id) {
            return
          }

          const access_token = await getAccessToken()
          if (!access_token) {
            return
          }

          dispatch(setIsLoading(true))

          const cuboid = await createMaskingRegion(
            access_token,
            project_id,
            inspection_area_id,
            editingCuboid,
            showErrorModal,
          )

          dispatch(setIsLoading(false))

          if (!cuboid) {
            return
          }

          mixpanel.track('Created mask region', {
            'Number of mask region': maskRegions.length + 1,
          })

          dispatch(addMaskRegion(cuboid))
          dispatch(setCuboidAnchor(undefined))
          dispatch(setEditingCuboid(undefined))

          dispatch(setAttentionText({ message: '' }))
          changeTool(EDITOR_TOOLS.MOVE, true)
        }, [
          editingCuboid,
          project_id,
          inspection_area_id,
          maskRegions.length,
          dispatch,
          getAccessToken,
          showErrorModal,
          changeTool,
        ]),
        isShown: useCallback(() => selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID, [selectedTool]),
        isLoading: useCallback(() => isLoading, [isLoading]),
        isDisabled: useCallback(
          () => isLoading || !cuboidAnchor || cuboidAnchor.points.length < EDITOR_REQUIRED_ANCHORS.cuboid,
          [isLoading, cuboidAnchor],
        ),
      },
      reset: {
        onClick: useCallback(() => dispatch(reset()), [dispatch]),
        isShown: useCallback(() => selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID, [selectedTool]),
        isDisabled: useCallback(
          () => isLoading || (!editingCuboid && !cuboidAnchor?.points.length),
          [isLoading, editingCuboid, cuboidAnchor],
        ),
      },
    },
  }
}
