import { CircleAnchorIcon, DistanceLabelProps } from 'interfaces/canvas'

export const DISTANCE_LABEL_STYLES: Partial<DistanceLabelProps> = {
  color: '#f00',
  labelBgColor: '#f00',
  labelTextColor: '#fff',
  anchorProps: { color: '#888' },
}

export const DISTANCE_LABEL_STYLES_EDITABLE: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES,
  anchorProps: {
    outlineOpacity: 0,
    color: '#248CE4',
    scale: 1.3,
    hoverScale: 1.4,
    icon: CircleAnchorIcon.MOVE,
    iconScale: 1.1,
    iconColor: 'white',
  },
}

export const DISTANCE_LABEL_STYLES_EDITABLE_DRAGGING: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES,
  lineColor: '#f00',
  anchorProps: {
    outlineOpacity: 0,
    color: '#f00',
    scale: 1.3,
    hoverScale: 1.4,
    icon: CircleAnchorIcon.MOVE,
    iconScale: 1.1,
    iconColor: 'white',
  },
}

export const DISTANCE_LABEL_STYLES_WORKING: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES,
  anchorProps: { color: '#f00' },
}
