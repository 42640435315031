import { FC } from 'react'

import { Box, Button, Tooltip, VStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'

import { CheckCircleIcon, ResetIcon, UndoIcon } from 'assets/icons'

import { useGlobalModalContext } from 'contexts/GlobalModal'

import { MODAL_TYPES } from 'config/constants'

import { EditorConfig } from 'interfaces/editor'

import { Tools } from '../tools/setup'

interface ToolsPanelProps {
  toolsConfig: (
    | {
        key: string
        auth: boolean
        hook: EditorConfig
      }
    | undefined
  )[]
}

const ToolsPanel = ({ toolsConfig }: ToolsPanelProps) => {
  // Context
  const { showModal } = useGlobalModalContext()

  // Module panels
  const modulePanels = (
    Tools.map((tool) => [
      tool.components?.panel,
      ...(tool.toolbar?.variants?.map((variant) => variant.components?.panel) || []),
    ])
      .flat()
      .filter(Boolean)
      .flat() as ([FC, number] | FC)[]
  ).map<[FC, number]>((panel) => (panel && Array.isArray(panel) ? panel : [panel, Number.MAX_SAFE_INTEGER]))

  // Merge all tools and sort by order
  const toolsPanels = [...modulePanels].sort((a, b) => a[1] - b[1]).map((panel) => panel[0])

  return (
    <VStack w="100%" gap={0} data-testid="tools-panel" className="panel tools-panel">
      {toolsPanels.map((ToolPanel) => (
        <ToolPanel key={`tool-panels-${ToolPanel.name}`} />
      ))}

      <VStack className="tool-buttons">
        {toolsConfig.map(
          (tool) =>
            tool?.auth &&
            tool.hook?.buttons?.undo?.isShown() && (
              <Button
                key={`tool-action-button-undo-${tool.key}`}
                data-testid={`action-button-undo-${tool.key}`}
                variant="panel-dark"
                rightIcon={<UndoIcon />}
                size={isTablet ? 'lg' : 'md'}
                fontSize="md"
                onClick={() => {
                  document.dispatchEvent(new Event('editor-undo'))
                }}
                isDisabled={tool.hook.buttons.undo.isDisabled()}
                width="full"
                justifyContent="space-between"
              >
                {tool.hook.buttons.undo.label || '点をひとつ戻す'}
              </Button>
            ),
        )}
        {toolsConfig.map(
          (tool) =>
            tool?.auth &&
            tool.hook?.buttons?.reset?.isShown() && (
              <Button
                key={`tool-action-button-reset-${tool.key}`}
                data-testid={`action-button-reset-${tool.key}`}
                variant="panel-dark"
                rightIcon={<ResetIcon />}
                size={isTablet ? 'lg' : 'md'}
                fontSize="md"
                onClick={() => {
                  showModal({
                    title: `リセット`,
                    body: '変更をリセットしますか？',
                    confirmText: 'リセット',
                    modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
                    onConfirm: () => {
                      tool.hook.buttons!.reset!.onClick()
                      mixpanel.track('Reset tool', {
                        Tool: tool.key,
                        Reason: 'Reset button',
                      })
                      return true
                    },
                  })
                }}
                isDisabled={tool.hook.buttons.reset.isDisabled()}
                width="full"
                justifyContent="space-between"
              >
                リセット
              </Button>
            ),
        )}
        {toolsConfig.map(
          (tool) =>
            tool?.auth &&
            tool.hook?.buttons?.submit?.isShown() && (
              <Tooltip
                key={`tool-action-submit-tooltip-${tool.hook.buttons.submit.key}`}
                label={tool.hook.buttons.submit.tooltip}
                placement="left"
                hasArrow
              >
                <Box w="100%">
                  <Button
                    data-testid={`action-button-${tool.hook.buttons.submit.key || 'submit'}`}
                    colorScheme="primary"
                    rightIcon={tool.hook.buttons.submit.icon || <CheckCircleIcon />}
                    size={isTablet ? 'lg' : 'md'}
                    fontSize="md"
                    onClick={tool.hook.buttons.submit.onClick}
                    isLoading={tool.hook.buttons.submit.isLoading ? tool.hook.buttons.submit.isLoading() : false}
                    isDisabled={tool.hook.buttons.submit.isDisabled()}
                    spinnerPlacement="end"
                    loadingText={tool.hook.buttons.submit.loadingLabel}
                    width="full"
                    justifyContent="space-between"
                  >
                    {tool.hook.buttons.submit.label}
                  </Button>
                </Box>
              </Tooltip>
            ),
        )}
      </VStack>
    </VStack>
  )
}

export default ToolsPanel
