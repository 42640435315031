import { useCallback, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Box3 } from 'three'

import { useEditorContext } from 'contexts/Editor'

import { EditorPanelItem, ElementsPanelConfig } from 'interfaces/editor'
import { Cylinder, Polygon } from 'interfaces/shape'

import { isCylinderWithinBoundingBox } from 'services/Shape'
import { findDiameterKeyByValue, meterToMillimeter, zeroPad } from 'services/Util'
import { getVolumeBoundingBox, getVolumeEstimationItems } from 'services/VolumeEstimation'

import DiameterSelection from '../components/DiameterSelection'
import { deleteWorkingDistanceLabelAtIndex } from '../store'

const useElementsPanel = (): ElementsPanelConfig => {
  // Context
  const { shapes, inspectionItems } = useEditorContext()

  // Store
  const dispatch = useAppDispatch()
  const workingDistanceLabels = useSelector((state: RootState) => state.toolRebarDetection.workingDistanceLabels)
  const isLoading = useSelector((state: RootState) => state.toolRebarDetection.isLoading)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  // Derived
  const volumes = useMemo(() => getVolumeEstimationItems(inspectionItems), [inspectionItems])

  /**
   * Get bounding box of volumes
   */
  const volumeBbox: { [name: string]: Box3 } = useMemo(
    () =>
      volumes.reduce((prev, volume) => {
        const bbox = getVolumeBoundingBox(
          shapes.polygons?.filter((shape) => volume.shape_ids?.polygons?.includes(shape.shape_id)) as [
            Polygon,
            Polygon,
          ],
        )

        return {
          ...prev,
          [volume.inspection_item_id!]: bbox,
        }
      }, {}),
    [volumes, shapes],
  )

  /**
   * Sort rebar into volume if it's inside and outside of volume.
   * Map key is volume inspection item id while non-volume rebar is stored under 'non-volume'.
   */
  const rebars: { [name: string]: EditorPanelItem[] } = useMemo(() => {
    const rebarMap: { [name: string]: EditorPanelItem[] } = { 'non-volume': [] }

    // We generate panel items first to maintain index for naming
    const panelItems = shapes.cylinders.map<EditorPanelItem>((item, index) => ({
      key: `panel-rebar-${item.shape_id}`,
      label: `鉄筋${zeroPad(index + 1, 3)} (${item.diameter ? findDiameterKeyByValue(meterToMillimeter(item.diameter)) : 'D??'})`,
      item,
      onDelete: isAllowedToModify,
    }))

    // Sort cylinders into volume
    panelItems.forEach((item) => {
      Object.entries(volumeBbox).forEach(([volumeId, bbox]) => {
        if (isCylinderWithinBoundingBox(item.item as Cylinder, bbox)) {
          rebarMap[volumeId] = rebarMap[volumeId] || []
          rebarMap[volumeId].push(item)
        }
      })
    })

    // Any leftover cylinders are non-volume
    rebarMap['non-volume'] = panelItems.filter((cylinder) => !Object.values(rebarMap).flat().includes(cylinder))

    return rebarMap
  }, [volumeBbox, shapes, isAllowedToModify])

  return {
    getPanelItems: useCallback(
      (parent?: EditorPanelItem['item']) => {
        // Rebar is both a parent and a child depending on whether it's inside volume or not
        let cylinders: EditorPanelItem[] = []
        if (parent && 'item_type' in parent && parent?.item_type === 'volume') {
          cylinders = rebars[parent.inspection_item_id!] || []
        } else if (!parent) {
          cylinders = rebars['non-volume']
        }

        if (!parent && workingDistanceLabels.length) {
          cylinders = cylinders.concat(
            workingDistanceLabels.map<EditorPanelItem>((label, index) => ({
              key: `panel-rebar-${label.id}`,
              label: selectedElementIds.includes(label.id)
                ? `鉄筋${zeroPad(shapes.cylinders.length + index + 1, 3)}`
                : `鉄筋${zeroPad(shapes.cylinders.length + index + 1, 3)} (${label.diameter ? findDiameterKeyByValue(label.diameter) : 'D??'})`,
              dropdown: selectedElementIds.includes(label.id) ? <DiameterSelection label={label} /> : undefined,
              item: label,
              isWorking: true,
              isSaving: isLoading,
              onDelete: isLoading ? false : () => dispatch(deleteWorkingDistanceLabelAtIndex(index)),
            })),
          )
        }

        if (cylinders.length) {
          return [
            {
              key: 'panel-rebar',
              label: `鉄筋${!parent ? ' (体積なし)' : ''}`,
              isVirtualContainer: true,
              isCountable: true,
              children: cylinders,
            },
          ]
        }

        return []
      },
      [rebars, workingDistanceLabels, isLoading, shapes, selectedElementIds, dispatch],
    ),
  }
}

export default useElementsPanel
