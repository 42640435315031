import { useCallback, useContext, useEffect, useMemo } from 'react'

import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import useDetectPolygonsVolume from 'pages/projects/editor/tools/VolumeEstimation/Polygon/hooks/useDetectPolygonsVolume'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Vector3 } from 'three'

import { INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_TOOLS } from 'config/constants'

import { EditorConfig } from 'interfaces/editor'
import { Editor, ShapeKeyType, VolumeEstimationMethods } from 'interfaces/interfaces'
import { PlaneSide } from 'interfaces/shape'

import { pointsOrientation } from 'services/Points'
import { withPromiseContained } from 'services/Util'
import {
  VOLUME_ESTIMATION_IN_PROGRESS_TEXT,
  detectPolygonsVolume,
  pollAsyncJobStatus,
  reevaluateVolumeEstimation,
} from 'services/VolumeEstimation'

import { Mode } from '../../utils'
import { reset, resetWorkingPoints, setIsSaving, setIsUpdating, setRebarModelledConfirmed } from '../store'

export default function useEditor(props: Editor): EditorConfig {
  // Props
  const { selectedTool, isPreviousTool, inspectionSheet, inspectionItems, changeIsJobRunning, fetchInspectionItems } =
    props

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const isSaving = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.isSaving)
  const isUpdating = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.isUpdating)
  const isDirty = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.isDirty)
  const planes = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.planes)
  const project = useSelector((root: RootState) => root.page.project)
  const inspectionArea = useSelector((root: RootState) => root.page.inspectionArea)
  const maskRegions = useSelector((root: RootState) => root.maskPCD.regions)
  const planeCreationOrder = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.planeCreationOrder)
  const rebarModelledConfirmed = useSelector(
    (root: RootState) => root.toolVolumeEstimationPolygon.rebarModelledConfirmed,
  )
  const estimationMethod = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.estimationMethod)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)
  const jobRunningTool = useSelector((state: RootState) => state.editor.jobRunningTool)

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.VOLUME_POLYGON, [selectedTool])
  const selectedVolumes = useMemo(
    () =>
      inspectionItems.filter(
        (item) => item.item_type === 'volume' && selectedElementIds.find((id) => item.shape_ids.polygons.includes(id)),
      ),
    [selectedElementIds, inspectionItems],
  )
  const selectedVolume = selectedVolumes[0]
  const isSelectedMultiple = selectedVolumes.length > 1
  const mode = useMemo(() => {
    if (isToolSelected || isSaving) return Mode.Modelling
    if (selectedVolume && selectedTool === EDITOR_TOOLS.MOVE && jobRunningTool === '') return Mode.Reevaluation
    return Mode.None
  }, [isToolSelected, isSaving, selectedTool, selectedVolume, jobRunningTool])

  // Hooks
  const { poll } = useDetectPolygonsVolume(props, ShapeKeyType.POLYGON, {
    onFinished: useCallback(() => {
      dispatch(reset())
    }, [dispatch]),
  })

  // Sort points in clock-wise
  const orderedPoints = useMemo(() => {
    const upperPoints = planes.upper?.points.slice(0, -1) || []
    const lowerPoints = planes.lower?.points.slice(0, -1) || []
    const orientation = pointsOrientation(upperPoints.map((point) => new Vector3(...point)))

    const result = {
      upper: orientation === 'clockwise' ? upperPoints : [...upperPoints].reverse(),
      lower: orientation === 'clockwise' ? lowerPoints : [...lowerPoints].reverse(),
    }

    return result
  }, [planes.upper, planes.lower])

  /**
   * Toggle info panels when tool is selected
   */
  useEffect(() => {
    if (!isToolSelected) {
      if (isPreviousTool(EDITOR_TOOLS.VOLUME_POLYGON) && selectedTool !== EDITOR_TOOLS.FOCUS) {
        dispatch(resetWorkingPoints())
      }
    }
  }, [isToolSelected, selectedTool, isPreviousTool, planes.upper, planes.lower, dispatch])

  /**
   * Reset on unmount
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  /**
   * Submit button for creating a new volume
   */
  const buttonsCreate = {
    submit: {
      key: 'save-polygon',
      label: '体積を測定',
      loadingLabel: '測定中',
      isLoading: () => isSaving,
      isShown: () => isToolSelected || isSaving,
      isDisabled: () =>
        planes.upper === null ||
        planes.lower === null ||
        isSaving ||
        (estimationMethod === VolumeEstimationMethods.Advanced && !rebarModelledConfirmed),
      onClick: async () => {
        if (!project || !inspectionArea?.down_sampled_file?.path || !inspectionSheet?.inspection_sheet_id) return

        const token = await getAccessToken()
        if (!token) return

        dispatch(setIsSaving(true))
        changeIsJobRunning(true)
        dispatch(setAttentionText({ message: VOLUME_ESTIMATION_IN_PROGRESS_TEXT }))

        const result = await detectPolygonsVolume(
          token,
          project.project_id,
          inspectionArea.inspection_area_id,
          inspectionSheet.inspection_sheet_id,
          ShapeKeyType.POLYGON,
          {
            ...INITIAL_SHAPE_STATE(),
            polygons: [
              {
                points: orderedPoints.lower,
                plane_side: PlaneSide.LOWER,
              },
              {
                points: orderedPoints.upper,
                plane_side: PlaneSide.UPPER,
                is_virtual: planeCreationOrder[1] === PlaneSide.UPPER, // if starting from lower plane, upper plane is always virtual
              },
            ],
          },
          maskRegions.filter((region) => !region.invisible),
          estimationMethod,
          showErrorModal,
        )

        if (result) {
          void poll(result)
          return
        }

        dispatch(setIsSaving(false))
        changeIsJobRunning(false)
        dispatch(setAttentionText({ message: '' }))
      },
    },
    reset: {
      onClick: useCallback(() => dispatch(reset()), [dispatch]),
      isShown: useCallback(() => isToolSelected || isSaving, [isToolSelected, isSaving]),
      isDisabled: useCallback(() => isSaving || !isDirty, [isSaving, isDirty]),
    },
    undo: {
      isShown: useCallback(() => isToolSelected || isSaving, [isToolSelected, isSaving]),
      isDisabled: useCallback(() => isSaving || !isDirty, [isSaving, isDirty]),
    },
  }

  /**
   * Submit button for saving new estimation method
   */
  const buttonsUpdate = {
    submit: {
      key: 'save-polygon',
      label: '体積を再測定',
      loadingLabel: '体積を再測定中',
      isLoading: () => isUpdating,
      isShown: () => mode === Mode.Reevaluation || isUpdating,
      isDisabled: () =>
        !(
          estimationMethod === VolumeEstimationMethods.Standard ||
          (estimationMethod === VolumeEstimationMethods.Advanced && rebarModelledConfirmed)
        ) ||
        isUpdating ||
        isSelectedMultiple,
      onClick: useCallback(async () => {
        if (
          !selectedVolume ||
          !project ||
          !inspectionArea ||
          !inspectionSheet ||
          (estimationMethod === VolumeEstimationMethods.Advanced && !rebarModelledConfirmed)
        )
          return

        const token = await getAccessToken()
        if (!token) return

        dispatch(setIsUpdating(true))
        dispatch(setAttentionText({ message: '体積の再評価中...' }))
        await withPromiseContained(async () => {
          const jobToken = await reevaluateVolumeEstimation(
            token,
            project.project_id,
            inspectionArea.inspection_area_id,
            inspectionSheet.inspection_sheet_id,
            selectedVolume.inspection_item_id!,
            estimationMethod,
            showErrorModal,
          )
          if (!jobToken) return

          const result = await pollAsyncJobStatus(token, jobToken, showErrorModal)
          if (!result) return

          await fetchInspectionItems()
        }).finally(() => {
          dispatch(setIsUpdating(false))
          dispatch(setAttentionText({ message: '' }))
          dispatch(setRebarModelledConfirmed(false))
        })
      }, [
        selectedVolume,
        estimationMethod,
        rebarModelledConfirmed,
        project,
        inspectionArea,
        inspectionSheet,
        showErrorModal,
        getAccessToken,
        fetchInspectionItems,
        dispatch,
      ]),
    },
  }

  return {
    buttons: mode === Mode.Modelling ? buttonsCreate : buttonsUpdate,
  }
}
