import { useCallback, useEffect, useMemo, useState } from 'react'

import { difference } from 'lodash'
import { AttentionTextIcon, setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { setIsDragging } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Plane, Vector3 } from 'three'

import { useEditorContext } from 'contexts/Editor'

import { EDITOR_ANCHOR_CLOSING_PROXIMITY, EDITOR_TOOLS, PLANE_SIDE_LABELS } from 'config/constants'
import { PLANE_OPACITY, PLANE_SIDE_COLOR } from 'config/styles'

import { PointArray } from 'interfaces/attribute'
import { CanvasConfig, CanvasEventsExtra } from 'interfaces/editor'
import {
  MeshPoints,
  PolygonPlaneMeshProps,
  PolygonPlaneMeshTransformableProps,
  TransformTypes,
} from 'interfaces/interfaces'
import { PlaneSide, Polygon } from 'interfaces/shape'

import { findCenter } from 'services/Points'
import { getPlaneColor, getPlaneStyles, isHoverSelected } from 'services/Util'
import { getPlaneLabel, getVolumeEstimationItem } from 'services/VolumeEstimation'

import {
  DrawingStage,
  PANEL_WORKING_PLANE_LOWER_ID,
  PANEL_WORKING_PLANE_UPPER_ID,
  WORKING_PLANE_LOWER_ID,
  WORKING_PLANE_UPPER_ID,
  addWorkingPoint,
  completeBottomPlane,
  completeTopPlane,
  setClosing,
  setDrawingStage,
  setHeightSelectionPoints,
  setWorkingPoints,
  swapPlanes,
  undoLastWorkingPoint,
  updateLastWorkingPoint,
} from '../store'
import { useAnchors } from './useAnchors'

/**
 * Allowed transform types when adjusting top plane.
 */
const ALLOWED_TRANSFORM_TYPES = [TransformTypes.ROTATE]

/**
 * Main function for the Volume Estimation Polygon tool.
 */
export default function useMainCanvas(): CanvasConfig {
  // Context
  const { selectedTool, shapes, inspectionItems, setMeshPoints, isJobRunning } = useEditorContext()

  // States
  const [onDownData, setOnDownData] = useState<{ position: number[]; timestamp: number } | undefined>(undefined) // position here is screen position, not canvas coordinates.
  const [lastTouchPoint, setLastTouchPoint] = useState<PointArray | undefined>(undefined)
  const [lastTouchClient, setLastTouchClient] = useState<{ x: number; y: number } | undefined>(undefined)
  const [threePlanes, setThreePlanes] = useState<Record<PlaneSide | 'working', Plane>>(
    {} as Record<PlaneSide | 'working', Plane>,
  ) // The 'three' here refers to ThreeJS, but also coincidentally the 3 types of plane; working, upper, and lower.

  // Store
  const dispatch = useAppDispatch()
  const isClosing = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.isClosing)
  const workingPoints = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.workingPoints)
  const completedPlanes = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.planes)
  const drawingStage = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.drawingStage)
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const planeCreationOrder = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.planeCreationOrder)
  const heightSelectionPoints = useSelector(
    (state: RootState) => state.toolVolumeEstimationPolygon.heightSelectionPoint,
  )
  const hoveredElementId = useSelector((state: RootState) => state.editor.hoveredElementId)
  const dimmedElementIds = useSelector((state: RootState) => state.editor.dimmedElementIds)
  const hiddenElementIds = useSelector((state: RootState) => state.editor.hiddenElementIds)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.VOLUME_POLYGON, [selectedTool])
  const currentPlaneSide = planeCreationOrder[drawingStage === DrawingStage.Draw ? 0 : 1]
  const currentOtherPlaneSide = planeCreationOrder[drawingStage === DrawingStage.Draw ? 1 : 0]

  // Hooks initialization
  const { anchors, movingAnchor, onMoveAnchor, onEndMoveAnchor } = useAnchors(threePlanes, lastTouchClient)

  /**
   * Attention messages for each drawing stage.
   */
  const attentionMessages = useMemo(
    (): Record<DrawingStage, { message: string; icon?: keyof typeof AttentionTextIcon }> => ({
      [DrawingStage.Draw]: {
        message: `${
          planeCreationOrder[0] === PlaneSide.LOWER ? '斫り面' : '仕上がり面'
        }の頂点を選択してください。最初の点を選択すると平面を作成完了します。\n(最初に作成する面は右側のパネルから選択することができます。)`,
        icon: planeCreationOrder[0] === PlaneSide.LOWER ? 'bottomPlane' : 'topPlane',
      },
      [DrawingStage.Height]: {
        message: `${
          planeCreationOrder[1] === PlaneSide.LOWER ? '斫り面' : '仕上がり面'
        }を作成する位置を選択してください。\n(作成する面は右側のパネルから選択することができます。)`,
        icon: planeCreationOrder[1] === PlaneSide.LOWER ? 'bottomPlane' : 'topPlane',
      },
      [DrawingStage.Complete]: {
        message:
          '右下の「体積を測定」をクリックしてください。\n平面を選び直す場合は右側の「検出中の要素」パネルから平面を削除してください。',
        icon: undefined,
      },
    }),
    [planeCreationOrder],
  )

  /**
   * If the plane order is changed, swap completed planes.
   */
  useEffect(
    () => {
      dispatch(swapPlanes())
    },
    // don't want to listen to dispatch either, not necessary and may introduce problems.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [planeCreationOrder],
  )

  /**
   * When workingsPoints is changed (new points added), or completedPlanes is changed (plane completed),
   * construct ThreeJS's Plane representation of them.
   */
  useEffect(() => {
    if (workingPoints.length < 3) return

    setThreePlanes((prev) => ({
      ...prev,
      working: new Plane().setFromCoplanarPoints(
        new Vector3(...workingPoints[0]),
        new Vector3(...workingPoints[1]),
        new Vector3(...workingPoints[2]),
      ),
    }))
  }, [workingPoints])
  useEffect(() => {
    if (completedPlanes.lower?.points) {
      setThreePlanes((prev) => ({
        ...prev,
        lower: new Plane().setFromCoplanarPoints(
          new Vector3(...completedPlanes.lower!.points[0]),
          new Vector3(...completedPlanes.lower!.points[1]),
          new Vector3(...completedPlanes.lower!.points[2]),
        ),
      }))
    }
  }, [completedPlanes.lower])
  useEffect(() => {
    if (completedPlanes.upper?.points) {
      setThreePlanes((prev) => ({
        ...prev,
        upper: new Plane().setFromCoplanarPoints(
          new Vector3(...completedPlanes.upper!.points[0]),
          new Vector3(...completedPlanes.upper!.points[1]),
          new Vector3(...completedPlanes.upper!.points[2]),
        ),
      }))
    }
  }, [completedPlanes.upper])

  /**
   * Set attention text based on the current drawing stage.
   */
  useEffect(() => {
    if (!isToolSelected) return

    if (attentionMessages[drawingStage]) {
      dispatch(setAttentionText(attentionMessages[drawingStage]))
    } else {
      dispatch(setAttentionText({ message: '' }))
    }
  }, [drawingStage, isToolSelected, attentionMessages, dispatch])

  /**
   * Set saved polygon vertices to meshPoints.
   */
  useEffect(() => {
    if (shapes.polygons) {
      const points = shapes.polygons.reduce((acc, polygon) => {
        if (polygon.positions) {
          acc[polygon.shape_id] = polygon.positions.map((point) => new Vector3(...point))
        }
        return acc
      }, {} as MeshPoints)

      setMeshPoints(points)
    }
  }, [shapes, setMeshPoints])

  /**
   * Complete drawing (set height)
   */
  const completeDrawing = useCallback(
    (isTouch: boolean) => {
      dispatch(
        (currentPlaneSide === PlaneSide.UPPER ? completeTopPlane : completeBottomPlane)({
          removeLast: !isTouch,
          isDoubleClicked: false, // setting height never uses double click
        }),
      )
      dispatch(setDrawingStage(DrawingStage.Complete))
    },
    [currentPlaneSide, dispatch],
  )

  /**
   * Event Handlers (onUp).
   * Will be called by both mouse and touch events.
   */
  const onUp = useCallback(
    (
      point: PointArray | undefined,
      { clientX, clientY, isTouch = false }: { clientX: number; clientY: number; isTouch?: boolean },
      { isDoubleClicked }: CanvasEventsExtra,
    ) => {
      const mouseStart = onDownData ? { ...onDownData } : undefined
      setOnDownData(undefined)

      const distanceFromDown = mouseStart
        ? Math.hypot(clientX - mouseStart.position[0], clientY - mouseStart.position[1])
        : 0
      if (distanceFromDown > 10) return // user has been dragging the canvas

      if (point) {
        if (drawingStage === DrawingStage.Height) {
          completeDrawing(isTouch)
          return
        }

        // Double clicked with more than 2 or 3 points or last point is near the first point.
        // Touch does not have the last anchor that follows the mouse which is what the extra anchor
        // check is needed for non-touch interactions
        const completesPolygon =
          (workingPoints.length > (isTouch ? 2 : 3) && isDoubleClicked) ||
          (workingPoints.length > (isTouch ? 2 : 3) &&
            new Vector3(...workingPoints[0]).distanceTo(new Vector3(...point)) < EDITOR_ANCHOR_CLOSING_PROXIMITY)

        if (drawingStage === DrawingStage.Draw) {
          if (completesPolygon) {
            dispatch(
              (currentPlaneSide === PlaneSide.UPPER ? completeTopPlane : completeBottomPlane)({
                removeLast: !isTouch,
                isDoubleClicked,
              }),
            )
            dispatch(setDrawingStage(DrawingStage.Height))
            dispatch(setClosing(false))
            // if the user double clicks with less than 3 points, ignore second click
          } else if (!isDoubleClicked) {
            // Get the other plane as reference point
            const plane = threePlanes.working
            if (!plane && workingPoints.length > 3) return

            dispatch(
              addWorkingPoint(
                workingPoints.length < 3 ? point : plane.projectPoint(new Vector3(...point), new Vector3()).toArray(),
              ),
            )
          }
        }
      }
    },
    [dispatch, completeDrawing, currentPlaneSide, drawingStage, workingPoints, onDownData, threePlanes],
  )

  /**
   * Event Handlers (onMove).
   * Will be called by both mouse and touch events.
   */
  const onMove = useCallback(
    (
      point: PointArray | undefined,
      { isTouch = false }: { isTouch?: boolean },
      mouseEvent?: React.MouseEvent<HTMLDivElement>,
    ) => {
      // Vertice adjustments during complete stage
      if (movingAnchor) {
        onMoveAnchor(point, mouseEvent)
        return
      }

      if (point) {
        // If the drawing is already complete, it all ends here
        if (drawingStage === DrawingStage.Complete) return

        // If one plane has been drawn, the next one will only require a single click for height
        const completedPlane = completedPlanes[currentOtherPlaneSide]
        if (drawingStage === DrawingStage.Height && completedPlane?.points) {
          dispatch(setHeightSelectionPoints(point))

          // Get the other plane as reference point
          const plane = threePlanes[currentOtherPlaneSide]
          if (!plane) return

          const distanceToPlane = plane.distanceToPoint(new Vector3(...point))

          // position + (direction * distance)
          const secondPlanePoints = completedPlane.points.map((pt) =>
            new Vector3(...pt).add(plane.normal.clone().multiplyScalar(distanceToPlane)).toArray(),
          )

          dispatch(setWorkingPoints(secondPlanePoints))
          return
        }

        // only mouse move will update the last point
        if (!isTouch) {
          let newPoint = point

          if (drawingStage === DrawingStage.Draw && workingPoints.length > 3) {
            // Get the other plane as reference point
            const plane = threePlanes.working
            if (!plane) return

            newPoint = plane.projectPoint(new Vector3(...point), new Vector3()).toArray()
          }

          // update last point to according to mouse position
          dispatch(updateLastWorkingPoint(newPoint))
        }
        return
      }

      if (drawingStage !== DrawingStage.Complete) dispatch(setHeightSelectionPoints(null))
    },
    [
      completedPlanes,
      currentOtherPlaneSide,
      dispatch,
      drawingStage,
      workingPoints,
      threePlanes,
      onMoveAnchor,
      movingAnchor,
    ],
  )

  // DEBUG STUFF FOR DIAGRAM ORIENTATION
  // const cameraProfile = useSelector((state: RootState) => state.toolCameraProfile.cameraProfile)
  // const polyA = shapes.polygons?.filter((poly) => poly.plane_side === PlaneSide.UPPER)[0]
  // const res1 = useMemo(() => {
  //   if (!polyA || !cameraProfile) return null

  //   const boundary = minAreaRectangleOfPolygon(polyA.vertices, polyA.positions)
  //   if (!boundary) return null

  //   const projected = getProjectedVertices(cameraProfile, boundary, 0, 0, polyA, 1) as ReturnType<
  //     typeof getProjectedVertices
  //   > & { allUpIntersections: (IntersectionResult3D | null)[]; allRightIntersections: (IntersectionResult3D | null)[] }
  //   if (!projected) return null // get instersection between center and every edge

  //   const cameraMatrix = new Matrix4().fromArray(cameraProfile.state.arcballState.cameraMatrix.elements)
  //   const cameraRotation = new Quaternion().setFromRotationMatrix(cameraMatrix)
  //   const cameraRightVector = new Vector3(1, 0, 0).applyQuaternion(cameraRotation)
  //   const cameraUpVector = new Vector3(0, 1, 0).applyQuaternion(cameraRotation)
  //   const vertices3d = polyA.positions.map((v) => new Vector3(...v))
  //   const polyCenter = new Vector3(...polyA.center!)

  //   projected.allUpIntersections = vertices3d
  //     .map<IntersectionResult3D | null>((v, idx) =>
  //       findIntersection3d(
  //         vertices3d[idx],
  //         vertices3d[(idx + 1) % vertices3d.length],
  //         new Vector3(...polyCenter),
  //         cameraUpVector
  //           .clone()
  //           .multiplyScalar(1)
  //           .add(new Vector3(...polyCenter)),
  //       ),
  //     )
  //     .filter((v) => !v?.isOpposite)
  //   projected.allRightIntersections = vertices3d.map<IntersectionResult3D | null>((v, idx) =>
  //     findIntersection3d(
  //       vertices3d[idx],
  //       vertices3d[(idx + 1) % vertices3d.length],
  //       new Vector3(...polyCenter),
  //       cameraRightVector
  //         .clone()
  //         .multiplyScalar(1)
  //         .add(new Vector3(...polyCenter)),
  //     ),
  //   )

  //   return projected
  // }, [polyA, cameraProfile])
  // END DEBUG STUFF

  return {
    objects: {
      circleAnchors: anchors,
      // DEBUG STUFF FOR DIAGRAM ORIENTATION
      // distanceLabels: useMemo((): DistanceLabelProps[] => {
      //   if (!cameraProfile) return []

      //   const polygon = shapes.polygons.length ? shapes.polygons[0] : null
      //   if (!polygon) return []

      //   if (res1?.result3d) {
      //     return [
      //       res1?.result3d.right
      //         ? {
      //             points: [res1?.result3d.right.edge[0].toArray(), res1?.result3d.right.edge[1].toArray()],
      //             anchorColor: 'lightgreen',
      //             bottomAnchorColor: 'green',
      //           }
      //         : null,

      //       // projected points
      //       res1?.result3d.up?.projectedPoint
      //         ? {
      //             points: [res1?.result3d.up.centroid.toArray(), res1?.result3d.up.projectedPoint.toArray()],
      //             anchorColor: 'lightgreen',
      //             lineColor: 'lightgreen',
      //           }
      //         : null,
      //       res1?.result3d.right?.projectedPoint
      //         ? {
      //             points: [res1?.result3d.right.centroid.toArray(), res1?.result3d.right.projectedPoint.toArray()],
      //             anchorColor: 'lightgreen',
      //             lineColor: 'lightgreen',
      //           }
      //         : null,

      //       res1?.result3d.right?.intersectionPoint
      //         ? {
      //             points: [res1?.result3d.right.centroid.toArray(), res1?.result3d.right.intersectionPoint.toArray()],
      //             anchorColor: 'yellow',
      //             lineColor: 'yellow',
      //           }
      //         : null,

      //       // debug - line2Start -> intersection 1
      //       res1?.result3d.up?.intersectionPoint
      //         ? {
      //             points: [res1?.result3d.up.centroid.toArray(), res1?.result3d.up.intersectionPoint.toArray()],
      //             anchorColor: 'pink',
      //             lineColor: 'pink',
      //             lineThickness: 0.005,
      //             lineStyle: LineStyle.Solid,
      //           }
      //         : null,

      //       ...res1.allUpIntersections.map<DistanceLabelProps | null>((intersection, idx) => {
      //         if (!intersection?.intersectionPoint) return null

      //         return {
      //           id: `debug-diagram-orientation-up-${idx}`,
      //           points: [intersection.centroid.toArray(), intersection.intersectionPoint.toArray()],
      //           label: `${intersection.vectorDiff.toFixed(2)}, opp: ${intersection.isOpposite}`,
      //           anchorProps: {
      //             color: 'purple',
      //             scale: 0.5,
      //           },
      //           lineColor: 'purple',
      //         }
      //       }),

      //       ...res1.allUpIntersections.map<DistanceLabelProps | null>((intersection, idx) => {
      //         if (!intersection?.projectedPoint) return null

      //         return {
      //           id: `debug-diagram-orientation-up-${idx}`,
      //           points: [intersection.centroid.toArray(), intersection.projectedPoint.toArray()],
      //           // label: intersection.vectorDiff.toFixed(2),
      //           anchorProps: {
      //             color: 'white',
      //             scale: 0.5,
      //           },
      //           lineColor: 'white',
      //         }
      //       }),
      //     ].filter(Boolean) as DistanceLabelProps[]
      //   }

      //   return []
      // }, [cameraProfile, shapes.polygons, res1]),
      // END DEBUG STUFF
      polygonPlaneMeshes: useMemo(
        () =>
          [
            // Get polygon from shapes and prep data for drawing.
            ...(shapes?.polygons
              .filter((polygon) => {
                const volumeItem = getVolumeEstimationItem(inspectionItems, polygon.shape_id)
                return (
                  volumeItem &&
                  (!hiddenElementIds.includes(polygon.shape_id) ||
                    [polygon.shape_id, volumeItem.inspection_item_id].includes(hoveredElementId))
                )
              })
              .map<PolygonPlaneMeshProps>((polygon) => {
                let showLabel = false
                const volumeItem = getVolumeEstimationItem(inspectionItems, polygon.shape_id)
                const isHighlighted =
                  selectedElementIds.includes(polygon.shape_id) ||
                  [polygon.shape_id, volumeItem?.inspection_item_id].includes(hoveredElementId)

                if (volumeItem) {
                  showLabel =
                    (isHoverSelected(volumeItem.inspection_item_id!, hoveredElementId, selectedElementIds) ||
                      !difference(volumeItem.shape_ids.polygons, selectedElementIds).length) &&
                    polygon.plane_side === PlaneSide.UPPER
                }

                // Force hide label if we're doing grid on the same volume to lessen the clutter
                if (showLabel) {
                  Object.values(intervals).forEach((interval) => {
                    if (polygon.shape_id === interval.topPlaneId) {
                      showLabel = false
                    }
                  })
                }

                const props: PolygonPlaneMeshProps = {
                  ...getPlaneStyles(
                    polygon,
                    selectedElementIds,
                    dimmedElementIds,
                    polygon.shape_id,
                    polygon.plane_side === PlaneSide.UPPER ? '#2094F3' : '#BE6225',
                  ),
                  id: polygon.shape_id,
                  polygon,
                  label: showLabel ? getPlaneLabel(polygon, inspectionItems) : undefined,
                  color: getPlaneColor(polygon, selectedElementIds),
                  transparent: polygon.plane_side === PlaneSide.UPPER,
                  selectable: true,
                  showGuides: true,
                  showGuidesLabel: isHighlighted,
                  guideLabelBgColor: PLANE_SIDE_COLOR[polygon.plane_side!],
                  guideLabelTextColor: 'white',
                  isOpenLoop: true,
                }

                // If user is on the Rebar detection tool, dim the upper plane
                if (selectedTool === EDITOR_TOOLS.CYLINDER && polygon.plane_side === PlaneSide.UPPER) {
                  props.opacity = PLANE_OPACITY.DIMMED
                }

                return props
              }) || []),
            // Working plane
            workingPoints.length
              ? ({
                  polygon: {
                    shape_id: 'working-volume-polygon-drawing',
                    positions: workingPoints,
                    vertices: workingPoints.map((v) => [v[0], v[1]] as [number, number]),
                    center: findCenter(workingPoints.map((v) => new Vector3(...v)))?.toArray(),
                    transformation: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
                  } as Polygon,
                  transparent: currentPlaneSide === PlaneSide.UPPER,
                  showGuides: true,
                  guideColor: isClosing ? 'red' : undefined,
                  label: PLANE_SIDE_LABELS[currentPlaneSide],
                  color: PLANE_SIDE_COLOR[currentPlaneSide],
                  labelBgColor: PLANE_SIDE_COLOR[currentPlaneSide],
                  labelTextColor: '#fff',
                  guideLabelBgColor: 'white',
                  guideLabelTextColor: 'black',
                  guideLabelOutlineColor: PLANE_SIDE_COLOR[currentPlaneSide],
                  opacity: currentPlaneSide === PlaneSide.UPPER ? PLANE_OPACITY.STANDARD : undefined,
                  selectable: false,
                } as PolygonPlaneMeshProps)
              : null,
            // Completed planes (bottom)
            completedPlanes.lower && !hiddenElementIds.includes(PANEL_WORKING_PLANE_LOWER_ID)
              ? {
                  polygon: {
                    shape_id: WORKING_PLANE_LOWER_ID,
                    positions: completedPlanes.lower.points,
                    vertices: completedPlanes.lower.points.map((v) => [v[0], v[1]] as [number, number]),
                    center: findCenter(completedPlanes.lower.points.map((v) => new Vector3(...v)))?.toArray(),
                    transformation: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
                  } as Polygon,
                  transparent: false,
                  showGuides: true,
                  label: PLANE_SIDE_LABELS.lower,
                  labelBgColor: PLANE_SIDE_COLOR.lower,
                  labelTextColor: '#fff',
                  color: PLANE_SIDE_COLOR.lower,
                  guideLabelBgColor: 'white',
                  guideLabelTextColor: 'black',
                  guideLabelOutlineColor: PLANE_SIDE_COLOR.lower,
                  selectable: false,
                }
              : null,
            // Completed planes (top). Only use this if order is upper -> lower.
            completedPlanes.upper && !hiddenElementIds.includes(PANEL_WORKING_PLANE_UPPER_ID)
              ? {
                  polygon: {
                    shape_id: WORKING_PLANE_UPPER_ID,
                    positions: completedPlanes.upper.points,
                    vertices: completedPlanes.upper.points.map((v) => [v[0], v[1]] as [number, number]),
                    center: findCenter(completedPlanes.upper.points.map((v) => new Vector3(...v)))?.toArray(),
                    transformation: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
                  } as Polygon,
                  showGuides: true,
                  label: PLANE_SIDE_LABELS.upper,
                  labelBgColor: PLANE_SIDE_COLOR.upper,
                  labelTextColor: '#fff',
                  color: PLANE_SIDE_COLOR.upper,
                  guideLabelBgColor: 'white',
                  guideLabelTextColor: 'black',
                  guideLabelOutlineColor: PLANE_SIDE_COLOR.upper,
                  opacity: completedPlanes.lower ? PLANE_OPACITY.STANDARD : PLANE_OPACITY.DIMMED,
                  selectable: false,
                }
              : null,
          ].filter(Boolean) as PolygonPlaneMeshProps[],
        [
          completedPlanes,
          currentPlaneSide,
          isClosing,
          inspectionItems,
          intervals,
          shapes,
          workingPoints,
          hoveredElementId,
          selectedElementIds,
          dimmedElementIds,
          selectedTool,
          hiddenElementIds,
        ],
      ),
      polygonPlaneMeshTransformable: useMemo(
        () =>
          [
            // Completed planes (top). Only use this if order is lower -> upper.
            // This is a dummy plane for TransformControls to control and will never be displayed to user.
            // Done this way because any changes must be reflected back to 'points' data. If not done this way, it will cause
            // double transformation, one from TransformControls and another from the already applied transformation.
            completedPlanes.upper &&
            !hiddenElementIds.includes(PANEL_WORKING_PLANE_UPPER_ID) &&
            drawingStage === DrawingStage.Complete &&
            planeCreationOrder[1] === PlaneSide.UPPER &&
            !isJobRunning
              ? ({
                  polygon: {
                    shape_id: 'working-volume-polygon-upper-plane-transformable',
                    positions: completedPlanes.upper.points,
                    vertices: completedPlanes.upper.points.map((v) => [v[0], v[1]] as [number, number]),
                    center: findCenter(completedPlanes.upper.points.map((v) => new Vector3(...v)))?.toArray(),
                    transformation: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
                  } as Polygon,
                  color: 'pink',
                  opacity: 0, // never shown
                  allowedTransforms: ALLOWED_TRANSFORM_TYPES, // use constant to prevent redeclaration which can cause useEffect on it to fire every re-render
                  pivotPoint: heightSelectionPoints,
                  enabled: !movingAnchor,
                } as PolygonPlaneMeshTransformableProps)
              : null,
          ].filter(Boolean) as PolygonPlaneMeshTransformableProps[],
        [
          completedPlanes,
          drawingStage,
          heightSelectionPoints,
          planeCreationOrder,
          movingAnchor,
          isJobRunning,
          hiddenElementIds,
        ],
      ),
    },
    events: {
      onTouchStart: (e: React.TouchEvent<HTMLDivElement>, point: PointArray | undefined) => {
        if (!isToolSelected) return
        if (drawingStage === DrawingStage.Height && point) dispatch(setIsDragging(true)) // toggling off is done in MainCanvas::onTouchEnd
        setLastTouchPoint(point)
        setLastTouchClient({ x: e.touches[0].clientX, y: e.touches[0].clientY })
        setOnDownData({ position: [e.touches[0].clientX, e.touches[0].clientY], timestamp: Date.now() })
      },
      onTouchMoveCapture: (e: React.TouchEvent<HTMLDivElement>, point: PointArray | undefined) => {
        if (!isToolSelected) return

        if (movingAnchor) {
          onMoveAnchor(point, undefined, e)
          return
        }

        setLastTouchPoint(point)
        setLastTouchClient({ x: e.touches[0].clientX, y: e.touches[0].clientY })
        onMove(point, { isTouch: true })
      },
      onTouchEnd: (e, extra: CanvasEventsExtra) => {
        if (!isToolSelected) return

        if (movingAnchor) {
          onEndMoveAnchor()
          return
        }

        // Last touch point and last touch client are obtained from touchstart and touchstart because touchmove does not have `touches` data.
        if (lastTouchPoint && lastTouchClient) {
          if (drawingStage === DrawingStage.Height && lastTouchPoint) {
            onMove(lastTouchPoint, { isTouch: true }) // call onMove one last time. If the user only taps but not drag, onTouchMoveCapture will not be called.
            completeDrawing(true)
          } else {
            onUp(lastTouchPoint, { clientX: lastTouchClient.x, clientY: lastTouchClient.y, isTouch: true }, extra)
          }
        }

        setLastTouchPoint(undefined)
      },
      onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isToolSelected || lastTouchClient) return
        setOnDownData({ position: [e.clientX, e.clientY], timestamp: Date.now() })
      },
      onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, points: PointArray | undefined, extra) => {
        if (!isToolSelected || lastTouchClient) return
        if (movingAnchor) onEndMoveAnchor()
        else onUp(points, { clientX: e.clientX, clientY: e.clientY }, extra)
      },
      onMove: (point: PointArray | undefined, mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isToolSelected || lastTouchClient) return
        onMove(point, {}, mouseEvent)
      },
      onUndo: () => {
        dispatch(undoLastWorkingPoint())
      },
    },
  }
}
