import { ToolbarDepthIcon } from 'assets/icons'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, USER_TYPES } from 'config/constants'

import { EditorTool, ToolbarCategory } from 'interfaces/editor'
import { ShapeKeyType } from 'interfaces/shape'
import { PermissionSets } from 'interfaces/validation'

import useEditor from './hooks/useEditor'
import useElementsPanel from './hooks/useElementsPanel'
import useMainCanvas from './hooks/useMainCanvas'

const DepthEstimation: EditorTool = {
  key: EDITOR_TOOLS.DEPTH,
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) =>
    permissionSets.MAIN_CANVAS.DETECT.includes(userType),
  toolbar: {
    icon: <ToolbarDepthIcon width="50%" height="50%" />,
    label: EDITOR_TOOLS_LABELS.DEPTH,
    category: ToolbarCategory.Manipulate,
  },
  hooks: {
    useEditor,
    useMainCanvas,
    useElementsPanel,
  },
  config: {
    volume: {
      required: true,
      selectable: true,
      onlyOneSelectable: true,
    },
    cylinder: {
      required: true,
    },
    selectableShapes: [ShapeKeyType.CYLINDER, ShapeKeyType.POLYGON],
  },
}

export default DepthEstimation
